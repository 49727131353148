// FORMATING
.format-text(@size, @line, @weight) {
  font-size: @size;
  line-height: @line;
  font-weight: @weight;
}

// MOD GENERATORS
.mix-mod-generator(@component, @mod-name, @prop, @value) {
  &.mod--@{mod-name} {
    .c-@{component} {
      @{prop}: @value;
    }
  }
}

// Dectect contrast
.text-color(@a) when (lightness(@a) >= 50%) {
  color: darken(@a, 60%);
}
.text-color(@a) when (lightness(@a) < 50%) {
  color: tint(@a, 80%);
}
.stroke-color(@a) when (lightness(@a) >= 50%) {
  stroke: darken(@a, 60%);
}
.stroke-color(@a) when (lightness(@a) < 50%) {
  stroke: tint(@a, 80%);
}

.mix-mod-color-generator(@component, @mod-name, @color) {
  &.mod--@{mod-name} {
    .c-@{component} {
      background: @color;
      .text-color(@color);
      .cmp-title {
        .text-color(@color);
      }
    }
  }
}

.mix-mod-main-color-generator(@mod-name, @color) {
  &.mod--@{mod-name} {
    background: @color;
    .text-color(@color);
    .cmp-title {
      .text-color(@color);
    }
  }
}
//mod for specific
.mix-mod-generator-selector(@component, @mod-name, @selector, @prop, @value) {
  &.mod--@{mod-name} {
    .c-@{component} {
      @{selector} {
        @{prop}: @value;
      }
    }
  }
}

// SPRITE
.mix-background-sprite(@size, @grid-position, @horizontal-position) {
  background-image: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/sprite-bhg.png");
  background-repeat: no-repeat;
  background-size: @size auto;
  background-position: @horizontal-position ~"calc("-@size * @grid-position ~")";
}

// ELLIPSIS
.ellipsis-multiline(@font-size, @line-height, @line-clamp) {
  font-size: @font-size;
  line-height: @line-height;
  -webkit-line-clamp: @line-clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: @line-height * @line-clamp + @line-clamp;
}

.border-height-hack(@lines-of-height) {
  overflow-y: hidden;
  margin-bottom: -@lines-of-height;
  padding-bottom: @lines-of-height;
  margin-top: -@lines-of-height;
  padding-top: @lines-of-height;
}

// POSITION
.center-align(@valueX, @valueY) {
  position: relative;
  top: @valueY;
  left: @valueX;
  transform: translateX(-@valueX) translateY(-@valueY);
}

.vertical-centering(@value) {
  position: relative;
  top: @value;
  transform: translateY(-@value);
}

.v-align-absolute(@value) {
  top: @value;
  transform: translateY(-@value);
}

.horizontal-align(@value) {
  position: relative;
  left: @value;
  transform: translateX(-@value);
}

.h-align-absolute(@value) {
  left: @value;
  transform: translateX(-@value);
}

.rotate(@rotate) {
  -ms-transform: rotate(@rotate); /* IE 9 */
  -webkit-transform: rotate(@rotate); /* Safari */
  transform: rotate(@rotate); /* Standard syntax */
}

.scale (@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: scale(@factor);
  -ms-transform: scale(@factor);
  -o-transform: scale(@factor);
  transform: scale(@factor);
}

//SPLIT LIST
.split-list(@numbecol,@gap) {
  -moz-column-count: @numbecol;
  -moz-column-gap: @gap;
  -webkit-column-count: @numbecol;
  -webkit-column-gap: @gap;
  column-count: @numbecol;
  column-gap: @gap;
}

// GRADIENTS
.shadow-overlay(@standar-direction, @start-opacity: 0.1, @start-position: 0%, @mid-opacity: 0.3, @mid-position: 50%, @final-opacity: 0.65, @final-position: 100%) {
  background: linear-gradient(@standar-direction, rgba(0, 0, 0, @start-opacity) @start-position, rgba(0, 0, 0, @mid-opacity) @mid-position, rgba(0, 0, 0, @final-opacity) @final-position);
}

// PLACEHODER STYLING
.placeholder(@color, @font-size, @font-style, @text-decoration, @opacity, @padding) {
  &::placeholder {
    color: @color;
    font-size: @font-size;
    font-style: @font-style;
    opacity: @opacity;
    padding: @padding;
    text-decoration: @text-decoration;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: @color;
    font-size: @font-size;
    font-style: @font-style;
    opacity: @opacity;
    padding: @padding;
    text-decoration: @text-decoration;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: @color;
    font-size: @font-size;
    font-style: @font-style;
    opacity: @opacity;
    padding: @padding;
    text-decoration: @text-decoration;
  }
}

/* MIXINS FOR EDIT MODE */

.cmp-extra-visible(@color, @text) {
    border: 2px solid @color;
    position: relative;
    border-radius: 2px;
    margin: 2px 0px 2px 0px;
    padding: 26px 6px 0;

    &:not(.cq-Editable-dom) {
        border-color: @green-off-edit;
        background: @semi-transparent;
        &::after {
            opacity: @off-edit-opacity;
        }
    }

    &::after {
        content: @text;
        background: @color;
        position: absolute;
        top: 0px;
        right: 0px;
        border-bottom-left-radius: 4px;
        font-size: 13px;
        padding: 0px 8px;
        color: @black;
        font-weight: 500;
    }
}

.cmp-extra-visible-bg(@color, @text) {
    border: 2px solid @color;
    background-image: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/transparent-bg.svg");
    background-size: 20px 20px;
    position: relative;
    // box-shadow: inset 0px 0px 2px 0px #575757;
    border-radius: 2px;
    margin: 2px 0px 2px 0px;
    padding: 26px 6px 0;

    &:not(.cq-Editable-dom) {
        border-color: @green-off-edit;
        background: @semi-transparent;
        &::after {
            opacity: @off-edit-opacity;
        }
    }
    
    &::after {
        content: @text;
        background: @color;
        position: absolute;
        top: 0px;
        right: 0px;
        border-bottom-left-radius: 4px;
        font-size: 13px;
        padding: 0px 8px;
        color: @black;
        font-weight: 500;
    }
}

.cmp-extra-visible-tag-left {
    &::after {
        left: 0;
        right: initial;    
        max-width: 178px;
        height: 26px;
    }
}
.cmp-extra-visible-tag-left-autowidth {
    &::after {
        left: 0;
        right: initial;    
        max-width: none;
        height: 26px;
    }
}

.cmp-extra-visible-low {
    border-color: @green-off-edit;
    &::after {
        opacity: 0.7;
    }
}

.cmp-extra-visible-inside-transparent {
    &:not(.cq-Editable-dom) {
        background: none;
    }
}

.cmp-edit-shadow-mode(@text, @color, @border-style) {
    outline: 2px @border-style @color;
    position: relative;
    &::after {
        content: @text;
        font-family: "adobe-clean", Helvetica, Arial, sans-serif;
        padding: 0 4px 1px 6px;
        font-style: normal;
        font-size: 8px;
        line-height: 1.1rem;
        font-weight: 300;
        letter-spacing: 0.08rem;
        line-height: 19px;
        height: 19px;
        color: white;
        background: @color;
        position: absolute;
        bottom: -1px;
        right: -1px;
    }
}
