/* COMMON ANIMATIONS */

/* TRANSITIONS */

.common-transition {
  transition: all 0.3s ease-out;
}

/* KEYFRAMES ANIMATIONS */

.animation-show-header-sticky {
  animation: showHeaderSticky 0.5s ease-out forwards;
}

@keyframes showHeaderSticky {
  from {
    top: -55px;
  }

  to {
    top: 0px;
  }
}

.animation-hide-header-sticky {
  animation: hideHeaderSticky 0.5s ease-out forwards;
}

@keyframes hideHeaderSticky {
  from {
    top: 0px;
  }

  to {
    top: -55px;
  }
}

.animation-show-booking-sticky {
  animation: showBookingSticky 0.5s ease-out forwards;
}

@keyframes showBookingSticky {
  from {
    bottom: -60px;
  }
  to {
    bottom: 0px;
  }
}

.animation-hide-booking-sticky {
  animation: hideBookingSticky 0.5s ease-out forwards;
}

@keyframes hideBookingSticky {
  from {
    bottom: 0px;
  }
  to {
    bottom: -60px;
  }
}

.show-right {
  animation: showRight 0.8s 1 ease-out forwards;
}

@keyframes showRight {
  from {
    right: ~"calc(-100% - 30px)";
  }
  to {
    right: 0px;
  }
}

.hide-right {
  animation: hideRight 0.8s 1 ease-in forwards;
}

@keyframes hideRight {
  from {
    right: 0px;
  }
  to {
    right: ~"calc(-100% - 30px)";
  }
}

.bounce {
  animation: bounce 1s ease-out;
  transform-origin: center bottom;
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);

    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);

    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.follow-btn-animation {
  position: relative;
  background-image: url("/content/dam/barcelo/commons/icons/ui/globo.svg");
  &__frame {
    position: absolute;
    top: 0px;
    left: 0px;
    &.fr-1 {
      animation: changeFr1 4.5s linear infinite;
    }
    &.fr-2 {
      z-index: -99;
      animation: changeFr2 4.5s linear infinite;
    }
    &.fr-3 {
      z-index: -99;
      animation: changeFr3 4.5s linear infinite;
    }
    &.fr-4 {
      z-index: -99;
      animation: changeFr4 4.5s linear infinite;
    }
    &.fr-5 {
      z-index: -99;
      animation: changeFr5 4.5s linear infinite;
    }
  }
}

@keyframes changeFr1 {
  0% {
    z-index: 1;
  }
  19% {
    z-index: 1;
  }
  20% {
    z-index: -99;
  }
  40% {
    z-index: -99;
  }
  60% {
    z-index: -99;
  }
  80% {
    z-index: -99;
  }
  100% {
    z-index: -99;
  }
}
@keyframes changeFr2 {
  0% {
    z-index: -99;
  }
  20% {
    z-index: 1;
  }
  39% {
    z-index: 1;
  }
  40% {
    z-index: -99;
  }
  60% {
    z-index: -99;
  }
  80% {
    z-index: -99;
  }
  100% {
    z-index: -99;
  }
}
@keyframes changeFr3 {
  0% {
    z-index: -99;
  }
  20% {
    z-index: -99;
  }
  40% {
    z-index: 1;
  }
  59% {
    z-index: 1;
  }
  60% {
    z-index: -99;
  }
  80% {
    z-index: -99;
  }
  100% {
    z-index: -99;
  }
}
@keyframes changeFr4 {
  0% {
    z-index: -99;
  }
  20% {
    z-index: -99;
  }
  40% {
    z-index: -99;
  }
  60% {
    z-index: 1;
  }
  79% {
    z-index: 1;
  }
  80% {
    z-index: -99;
  }
  100% {
    z-index: -99;
  }
}
@keyframes changeFr5 {
  0% {
    z-index: -99;
  }
  20% {
    z-index: -99;
  }
  40% {
    z-index: -99;
  }
  60% {
    z-index: -99;
  }
  80% {
    z-index: 1;
  }
  100% {
    z-index: 1;
  }
}

@-webkit-keyframes dots {
  0% {
    width: 120px;
  }
  100% {
    width: 0px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
    height: 0;
    width: 0;
    overflow-y: hidden;
    max-height: 0;
    min-height: 0;
    margin-bottom: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
    height: 0;
    width: 0;
    overflow-y: hidden;
    max-height: 0;
    min-height: 0;
    margin-bottom: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.3s forwards;
}

.fadeOut {
  animation: fadeOut 0.3s forwards;
}

.animation-banner {
  position: relative;
  &__frame {
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    animation: ribbonFr1 10s linear infinite;
    &.s13 {
      animation: ribbonFr1 13s linear infinite;
    }
    &.s10 {
      animation: ribbonFr1 10s linear infinite;
    }
    &.s7 {
      animation: ribbonFr1 7s linear infinite;
    }
  }
}
@keyframes ribbonFr1 {
  0% {
    transform: translateX(-100vw);
  }
  4% {
    transform: translateX(40vw);
    transition: 0.5s;
    transition-timing-function: ease-in-out;
  }
  6% {
    transform: translateX(49vw);
    transition: 0.5s;
    transition-timing-function: ease-in-out;
  }
  8% {
    transform: translateX(50vw);
    transition: 0.5s;
    transition-timing-function: ease-out;
  }
  95% {
    transform: translateX(50vw);
    transition: 1s;
    transition-timing-function: ease-in;
  }
  100% {
    transform: translateX(200vw);
  }
}

/* SPINNER LOADING */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  content: "";
  background: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/loading-gradwhite.svg");
}
.spinblue {
  content: "";
  background: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/loading-gradblue.svg");
}
.spin,
.spinblue {
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  background-size: 100% auto;
  position: absolute;
  top: ~"calc(50% - 12px)";
  left: ~"calc(50% - 12px)";
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}

@media (min-width: @desktop) {
  @keyframes ribbonFr1 {
    0% {
      transform: translateX(0vw);
    }
    4% {
      transform: translateX(40vw);
      transition: 0.5s;
      transition-timing-function: ease-in-out;
    }
    6% {
      transform: translateX(49vw);
      transition: 0.5s;
      transition-timing-function: ease-in-out;
    }
    8% {
      transform: translateX(50vw);
      transition: 0.5s;
      transition-timing-function: ease-out;
    }
    95% {
      transform: translateX(50vw);
      transition: 1s;
      transition-timing-function: ease-in;
    }
    100% {
      transform: translateX(100vw);
    }
  }
}
