//	 __          __              _             _
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |
//	                                     |___/
//
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

/* ICON COMPONENT */

.icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  .c-icon {
    margin: auto;
    line-height: 0px;
    width: 100%;
    height: inherit;
  }
}

.cmp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  height: inherit;
}

.svg-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.svg-icon {
  &.mod--blue {
    svg {
      path {
        fill: var(--b-blue);
        stroke: var(--b-blue);
      }
    }
  }
  &.mod--black {
    svg {
      path {
        fill: var(--b-blue);
        stroke: var(--b-blue);
      }
    }
  }
  &.mod--red {
    svg {
      path {
        fill: @b-red;
        stroke: @b-red;
      }
    }
  }
  &.mod--green {
    svg {
      path {
        fill: @b-green;
        stroke: @b-green;
      }
    }
  }
}

.mod--round-icon,
.mod--round-icon-big {
  border-radius: @circle;
  background: @white;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 2px;
  .c-icon {
    border-radius: @circle;
  }
}

.mod--round-icon-big {
  position: relative;
  box-shadow: 0px 0px 0px 1px rgb(216, 206, 141);

  .c-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 4px;
  }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
  .icon {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
  }

  .mod--round-icon-d {
    border-radius: @circle;
    background: @white;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 2px;
    .c-icon {
      border-radius: @circle;
    }
  }
}
