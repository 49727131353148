/* COMPARATOR-COMPARING - MOBILE FIRST (~ TABLET) */
.hotels-comparator,
.offers-comparator,
.meetings-comparator {
	.c-hotels-comparator {
		&__comparing {
			display: none;
			width: 100%;
			margin: 27px auto 0px;

			.comparing-wrapper {
				background: @white;
				width: 100%;

				.accordion-item {
					padding: 0px;

					.comparing__header {
						background: var(--b-blue);
						width: 100%;
						display: inline-block;
						font-size: 14px;
						line-height: 28px;
						color: @white;
						padding: 5px;
						padding-left: 10px;
						text-align: left;

						.svg-icon {
							margin-top: 7px;
							position: relative;
							float: right;
							margin-right: -2px;

							svg,
							img {
								height: 20px;
							}

							path {
								fill: white;
							}
						}
					}

					&:after {
						content: none;
					}

					&.active {
						.accordion-toggle {
							&:before {
								transform: rotate(-90deg);
							}
						}
					}

					.c-container__grid-wrapper {
						.comparing__container {
							&-title {
								display: none;
							}

							&-content {
								display: flex;
								flex-wrap: wrap;

								.c-card-hotel {
									width: 100%;
									margin: 0px;
									display: inline-flex;
									height: 55px;
									box-shadow: none;
									border-bottom: 1px solid @grey-70;

									.icon-close {
										width: 16px;
										height: 16px;
										font-size: 16px;
										display: block;
										color: var(--b-blue);
										top: 19px;
										z-index: 2;
									}

									&__image {
										display: none;
									}

									&__content {
										border: none;

										hr {
											display: none;
										}

										&-header {
											padding: 0 10px;
											height: 28px;
											width: 70%;

											&-title {
												.ellipsis;
												min-height: 28px;
												
												.c-cta {
													font-weight: 700;
													line-height: 22px;
													padding-top: 5px;
													font-size: 14px;
												}
											}

											&-location {
												font-size: 12px;
												display: none;
												max-width: auto;
												&-link {
													&-text {
														line-height: 1.3;
														margin: 6px 0;
													}
												}
											}
										}

										&-body {
											width: 55%;
											padding: 0px 10px 5px 10px;

											&-info {
												padding-top: 0px;
												height: auto;

												.c-tripadvisor {
													max-width: unset;
													display: inline-flex;

													.svg-icon {
														margin-right: 0px;
														padding-top: 0px;
													}

													&__rate {
														.ta__score,
														.ta__reviews {
															display: none;
														}
													}
												}

												&-label {
													display: none;
												}
											}

											&-list {
												display: none;
											}
										}

										&-footer {
											padding: 0 30px 0 0;
											position: absolute;
											right: 0;
											border-top: 0;
											margin-top: 0;
											text-align: right;
											width: 30%;
											height: 100%;

											&-price {
												flex-wrap: wrap;
												padding: 5px 0px;
												margin: 0px;
												justify-content: flex-end;

												height: 54px;
												flex-wrap: nowrap;
												flex-direction: column;

												&-claim {
													width: auto;
													font-size: 12px;
													color: @grey-70;
													.ellipsis;
													position: initial;
													line-height: 16px;
													align-self: self-end;
												}

												.c-price {
													// font-size: 16px;
													// align-items: baseline;
													align-self: self-end;
													// font-size: 28px;
    												line-height: 28px;

													&__currency {
														// font-size: 12px;
														// font-weight: 700;
													}

													&__type {
														display: none;
													}
												}
											}

											&.mod--hotel-my-barcelo {
												.c-price-header {
													.c-card-hotel__content-footer-price-claim {
														color: @white;
													}
												}
											}

											.c-cta {
												display: none;
											}
										}
									}
								}

								.comparing__placeholder-hotel {
									display: none;
								}
							}
						}

						.comparing__footer {
							display: flex;
							justify-content: center;
							align-items: center;
							margin: 5px auto;

							&-comparator-btn {
								.c-cta {
									font-size: 15px;
								}
							}

							&-close-btn {
								.c-cta {
									font-size: 16px;
									margin: 0 15px;
								}
							}
						}
					}
				}
				&.mod--stick-bottom {
					.accordion-item {
						.c-container__grid-wrapper {
							.comparing__container-content {
								.c-card-hotel {
									.c-card-hotel__content-footer {
										max-width: 30%;
										max-height: 54px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* COMPARATOR-COMPARING - MOBILE FIRST - OFFER ONLY */
.offers-comparator {
	.c-hotels-comparator__comparing .comparing-wrapper {
		.accordion-item .c-container__grid-wrapper .comparing__container-content {
			.c-card-hotel {
				&__content {
					position: relative;
					overflow-y: hidden;

					&-header {
						display: flex;
						height: 24px;
						padding-top: 4px;

						&-title {
							.ellipsis;

							.c-cta {
								text-decoration: none;
								color: black;

								&:after {
									display: none;
								}
							}
						}

						&-location {
							display: none;
						}

						.c-card-hotel__content-footer-location-link {
							display: none;
						}
					}

					&-body {
						width: 60%;
						position: relative;
						padding: 0;

						&-info {
							display: none;

							.c-tripadvisor {
								display: none;

								&__rate {

									.ta__score,
									.ta__reviews {
										display: none;
									}
								}
							}

							&-label {
								display: none;
							}
						}

						&-list {
							display: none;
						}

						.c-card-dates {
							width: 100%;
							padding: 2px 10px;
							left: 1px;
							position: relative;

							&__periods {
								&-date {
									display: flex;
									background: transparent;
									padding: 0px;
									min-width: 190px;

									&-claim {
										font-size: 14px;
										line-height: 24px;
										font-weight: 300;
										max-width: ~"calc(100% - 85px)";
										white-space: nowrap;
										.ellipsis;
									}

									&-travel {
										flex-shrink: 0;
									}
								}

								&-date:first-child {
									display: none;
								}

								&-separator {
									display: none;
								}
							}
						}

						.c-more-info-btn {
							display: none;
						}
					}

					&-footer {
						width: 70%;
						height: 100%;
						display: flex;
						align-items: center;
						padding: 0 10px;
						position: initial;

						.c-card-coupon__promo {
							display: flex;

							&-hotel {
								font-size: 14px;
								line-height: 22px;
								white-space: nowrap;
								font-weight: initial;
								.ellipsis;
								max-width: 90%;

								&:nth-child(2) {
									padding-left: 4px;
								}
							}

							&-discount {
								margin-right: 7px;
								width: 30%;
								margin-top: 0;
								position: absolute;
								right: 18px;
								top: 50%;
								transform: translateY(-50%);

								.c-offer {
									max-height: 32px;

									.c-card-hotel__content-footer-discount-claim {
										font-size: 12px;
										line-height: 20px;
										font-weight: 500;
										white-space: nowrap;
									}

									.c-card-hotel__content-footer-discount-value {
										font-size: 16px;
										line-height: 24px;
										font-weight: 700;
									}
								}
							}

							.c-card-hotel__content-footer-location-link {
								display: none;
							}
						}

						&-location {
							display: none;
						}
					}
				}
			}

			.c-flip-card {

				&__front,
				&__reverse {
					&_buttons {
						display: none;
					}
				}

				&__front {
					&_offer {
						padding: 5px 5px 5px 16px;

						.c-offer {
							width: 100%;
						}
					}
				}
			}
		}
		&.mod--stick-bottom {
			.accordion-item {
				.c-container__grid-wrapper {
					.comparing__container-content {
						.c-card-hotel {
							.c-card-hotel__content-header {
								.c-label {
									display: none;
								}
							}
							.c-card-hotel__content-footer {
								max-width: none;
							}
						}
					}
				}
			}
		}
	}
}

/* COMPARATOR-COMPARING - DESKTOP */
@media (min-width: @desktop) {

	.hotels-comparator,
	.offers-comparator,
    .meetings-comparator {
		.c-hotels-comparator {
			&__comparing {
				margin-top: 60px;

				.comparing-wrapper {
					.accordion-item {
						.comparing__header {
							text-align: center;
							padding: 5px;

							.svg-icon {
								position: relative;
								float: unset;
								margin-top: unset;
								margin-left: 9px;
							}
						}

						.c-container__grid-wrapper {
							.comparing__container {
								&-title {
									margin: 10px auto;
									font-size: 20px;
									line-height: 36px;
									display: flex;
									height: 100px;
									align-items: center;
									justify-content: center;
								}

								&-content {
									display: inline-flex;
									width: 100%;
									.mod--horizontal-card;

									.c-card-hotel {
										display: flex;
										height: auto;
										width: ~"calc(33.3% - 29px)";
										margin: 24px 29px 27px 0px;
										border: 1px solid @grey-40;

										&:last-child {
											margin-right: 0;
										}

										&__image {
											display: block;
											width: 136px;

											.c-container-image.mod--gradient-overlay {
												.cmp-image:after {
													content: none;
												}
											}

											.c-btn-over-image {
												display: none;
											}
										}

										.c-close-btn.icon-close {
											width: 16px;
											height: 16px;
											font-size: 16px;
											top: 5px;
											display: block;
										}

										&__content {
											border: none;
											width: 100%;

											&-header {
												padding: 20px 15px 0 15px;
												height: auto;
												width: 100%;

												&-title {
													.remove-ellipsis;

													.c-cta {
														font-size: 16px;
														line-height: 24px;
													}
												}

												&-location {
													display: block;
													font-size: 14px;
													margin: 5px 0px;
												}
											}

											&-body {
												padding: 0px 16px 10px;

												&-info {
													.c-tripadvisor {
														.svg-icon {
															width: 18px;
															height: 18px;
															margin-right: 0.35em;
															padding-top: 2px;
														}

														&__rate {
															display: flex;
															flex-wrap: wrap;
															flex-flow: column;
															align-items: flex-start;

															.ta__score {
																display: block;
															}

															.ta__dots {
																margin: 0px;
																width: 100%;
																margin-left: -24px;
															}
														}
													}
												}

												&-list {
													display: none;
												}
											}

											&-footer {
												border-top: 1px solid @grey-40;
												position: relative;
												padding: 0px 15px;
												width: 100%;
												&-price {
													justify-content: flex-end;
													height: 54px;
													flex-wrap: nowrap;
													flex-direction: column;
												}

												&-price-claim {
													.remove-ellipsis;
													// font-size: 12px;
													margin-bottom: 0;
													// line-height: 16px;
													position: absolute;
												}
												.c-price,
												.c-price__currency {
													line-height: 56px;
													// font-size: 32px;
													align-self: initial;
													height: 45px;
												}
											}
										}

										&.c-card-hotel-my-barcelo-price {
											.c-card-hotel__content-footer {
												padding: 0px 6px 0px 15px;
												&-price {
													margin: 0;
													.c-price-normal-myb {
														display: initial;
														padding-right: 2px;
														.c-price__value {
															font-size: 15px;
														}
														.c-price__currency {
															&:after {
																font-size: 16px;
																line-height: 20px;
															}
														}
													}
												}
											}
										}
									}

									.comparing__placeholder-hotel {
										color: @grey-50;
										border: 1px dashed @grey-40;
										display: flex;
										justify-content: center;
										align-items: center;
										width: ~"calc(33.3% - 29px)";
										margin: 24px 29px 27px 0px;
										padding: 0px 75px;
										text-align: center;
										font-weight: 500;
									}
								}
							}

							.comparing__footer {
								padding: 25px 0px 20px;
								border-top: 1px solid @grey-40;
								flex-flow: row-reverse;
								margin: 15px auto;

								&-comparator-btn {
									.c-cta {
										font-size: 16px;
									}
								}

								&-close-btn {
									.c-cta {
										font-size: 18px;
										margin: 0 15px;
									}
								}
							}
						}
					}
					&.mod--stick-bottom {
						.accordion-item {
							.c-container__grid-wrapper {
								.comparing__container-content {
									.c-card-hotel {
										.c-card-hotel__content-footer {
											max-width: 100%;
											max-height: none;
											height: auto;
											&-price {
												.c-price {
													font-size: 28px;
												}
												.c-price__currency {
													&:after {
														font-size: 20px;
														line-height: 20px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	/* COMPARATOR-COMPARING - DESKTOP - OFFER ONLY */
	.offers-comparator {
		.c-hotels-comparator__comparing .comparing-wrapper .accordion-item .c-container__grid-wrapper .comparing__container-content {
			.c-card-hotel {
				&__content {
					&-header {
						display: inherit;
						padding: 10px 30px 0px 15px;

						&-title {
							font-size: 16px;
							font-weight: 700;
							line-height: 20px;

							.c-cta {
								color: #155583;
								text-decoration: underline;

								&:after {
									display: initial;
								}
							}
						}

						.c-card-hotel__content-footer-location-link {
							display: initial;
						}

						.c-label {
							display: none;
						}
					}

					&-body {
						width: 100%;
						position: relative;
						padding: 10px;

						.c-card-dates {
							width: inherit;
							position: inherit;
							bottom: inherit;
							left: inherit;
							height: auto;
							padding: 0 4px;

							&__periods {
								&-date {
									display: inherit;
									background: @grey-20;
									padding: 0 4px;
									min-width: inherit;

									&-claim {
										font-size: 14px;
										line-height: 24px;
										font-weight: 300;
										max-width: inherit;
										white-space: inherit;
										overflow: auto;
									}

									&-travel {
										width: inherit;
										flex-shrink: inherit;
									}
								}

								&-date:first-child {
									display: inherit;
								}

								&-separator {
									display: inherit;
								}
							}
						}

						.c-more-info-btn {
							display: none;
						}
					}

					&-footer {
						border-top: 0px;
						padding: 0px;
						height: inherit;
						display: flex;
						align-items: center;

						.c-card-coupon__promo {
							flex-flow: column;
							display: flex;
							justify-content: space-between;
							padding: 0px 15px;

							&-hotel {
								font-size: 14px;
								line-height: 22px;
								font-weight: 700;
								.remove-ellipsis;
								max-width: 100%;
								line-height: 18px;

								&:nth-child(2) {
									padding-left: 0;
								}
							}

							&-discount {
								// margin-top: 20px;
								border-top: 1px solid rgb(216, 216, 216);
								margin-right: 0px;
								width: 100%;
								// padding: 0px 15px;
								padding: 10px 15px;
								position: relative;
								top: initial;
								transform: none;

								.c-offer {
									width: 100%;
									max-height: 32px;

									.c-card-hotel__content-footer-discount-claim {
										font-size: 12px;
										line-height: 20px;
										font-weight: 500;
										white-space: nowrap;
									}

									.c-card-hotel__content-footer-discount-value {
										font-size: 16px;
										line-height: 24px;
										font-weight: 700;
									}
								}
							}

							.c-card-hotel__content-footer-location-link {
								display: none;
							}
						}

						&-location {
							display: inherit;
							padding: 0px 15px;
							color: @grey-70;
							font-size: 12px;
							line-height: 13px;
							font-weight: 500;

							.svg-icon {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}



///* SPECIFIC STYLES FOR HOTEL COMPARATOR COMPARING CARD with myb prices *///
.hotels-comparator,
.offers-comparator,
.meetings-comparator {
	.c-hotels-comparator {
		&__comparing {
			.comparing-wrapper {
				&.mod--stick-bottom {
					.accordion-item {
						.c-card-hotel {
							&.c-card-hotel-my-barcelo-price {
								.c-card-hotel__content-footer {
									&.mod--hotel-my-barcelo {
										width: 42%;
										padding: 0 30px 0 0;
										.c-card-hotel__content-footer {
											&-price {
												flex-direction: column;
												flex-wrap: nowrap;
												justify-content: end;
												// &>* {
												// 	transform: scale(0.8);
												// }
												.c-price {
													&-myb {
														padding: 0;
														.c-card-hotel__content-footer-price-claim {
															overflow: visible;
														}
														&__container {
															padding-right: 24px;
															padding-bottom: 5px;
															padding-left: 12px;
														}
													}
													&-normal-myb {
														align-self: flex-start;
														display: none;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				/* sin ser mandatory que el accordion esté fixed en el bottom */
				.accordion-item {
					.c-card-hotel {
						&.c-card-hotel-my-barcelo-price {
							.c-card-hotel__content-footer {
								&.mod--hotel-my-barcelo {
									margin-top: auto;
									height: auto;
									.c-card-hotel__content-footer {
										&-price {
											.c-price {
												&-myb {
													padding: 0;
												}
											}
										}
									}
								}
							}
						}
					}

					/* cuando conviven myb prices con normal */
					.c-container__grid-wrapper {
						.comparing__container-content {
							.c-card-hotel__content-footer {
								margin-top: auto;
								height: 64px;
							}
						}
					}
				}
			}
		}
	}
}

/* COMPARATOR-COMPARING - DESKTOP */
@media (min-width: @desktop) {
	.hotels-comparator,
	.offers-comparator,
    .meetings-comparator {
		.c-hotels-comparator {
			&__comparing {
				.comparing-wrapper {

					&.mod--stick-bottom {
						z-index: 5;
						.accordion-item {
							.c-card-hotel {
								&.c-card-hotel-my-barcelo-price {
									.c-card-hotel__content-footer {
										&.mod--hotel-my-barcelo {
											padding: 0 15px;
											width: 100%;
											.c-card-hotel__content-footer {
												&-price {
													flex-direction: row;
													.c-price {
														&-normal-myb {
															display: block;
														}
														&-myb {
															.c-card-hotel__content-footer-price-claim {
																&__dstp {
																	padding: 6px 0 0 12px;
																	margin-right: 0;
																}
															}
															&__container {
																padding-right: 0;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
///* ENDS SPECIFIC STYLES FOR HOTEL COMPARATOR COMPARING CARD with myb prices *///
