/* COMPARATOR-COMPARATED - MOBILE FIRST (~ TABLET) */
.hotels-comparator,
.offers-comparator,
.meetings-comparator {
    .c-hotels-comparated {
        transition: all 0.5s ease-out;
        right: 0px;
        background: @white;
        position: fixed;
        top: 0px;
        .z-modal;
        height: 100vh;
        width: 100%;
        overflow-y: auto;
        &.data-info-1-hide .data-info-1,
        &.data-info-2-hide .data-info-2,
        &.data-info-3-hide .data-info-3 {
            display: none !important;
        }
        &.data-info-2-hide {
            .data-info-2 {
                display: none !important;
            }
        }
        &.data-info-3-hide {
            .data-info-3 {
                display: none !important;
            }
        }
        &.hidden-delete-items-compare {
            .hotel-card__delete {
                display: none;
            }
        }
        &.hidden {
            right: -100%;
            .c-hotels-comparated__header-nav {
                right: -100%;
            }
        }
        &__header {
            text-align: center;
            position: relative;
            background: @grey-20;
            &-title {
                font-size: 20px;
                line-height: 32px;
                font-weight: 700;
                padding: 57px 15px 0px 15px;
            }
            &-subtitle {
                padding: 0px 15px;
            }
            &-nav {
                transition: all 0.5s ease-out;
                right: 0px;
                position: fixed;
                display: flex;
                padding: 0px 15px;
                justify-content: space-between;
                align-items: center;
                background: @white;
                height: 40px;
                width: 100%;
                .z-navs;
                top: 0px;
                box-shadow: 0px 2px 3px 0px fade(@black, 30%);
                &-title {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 500;
                    color: @grey-70;
                }
                &-back-btn {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                }
            }
            &-share-btn {
                margin-top: 14px;
                .c-cta {
                    border: 2px solid @grey-90;
                    line-height: 30px;
                    text-transform: none;
                    .svg-icon {
                        margin-right: 6px;
                        margin-top: -3px;
                    }
                    &:hover {
                        .svg-icon {
                            .negative-svg-icon;
                        }
                    }
                }
            }
        }

        &__map {
            &-container {
                width: 100%;
                height: 700px;
            }
        }

        &__grid {
            .grid__header {
                display: flex;
                flex-wrap: wrap;
                background: @grey-20;
                .grid__filter {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 12px 15px 20px;
                    label {
                        font-weight: 300;
                        margin-left: 0.5rem;
                    }
                    .comparated-diferences,
                    .c-hotels-comparated__header-map-btn {
                        margin: auto;
                    }
                    .c-hotels-comparated__header-map-btn {
                        margin-top: 4px;
                    }
                    .c-fieldset {
                        margin: 0px;
                        &__row-wrap {
                            padding: 0 5px;
                        }
                        label {
                            .remove-ellipsis;
                        }
                    }
                }
                .grid__hotel-cards {
                    display: flex;
                    width: 100%;
                    .grid__hotel-card {
                        position: relative;
                        display: flex;
                        flex-flow: column;
                        min-width: 25%;
                        width: 100%;
                        border-style: solid;
                        border-color: @grey-20;
                        text-align: center;
                        border-width: 1px 1px 0px 1px;
                        border-top-left-radius: @soft-corners;
                        border-top-right-radius: @soft-corners;
                        &:last-child {
                            margin-right: 0px;
                        }
                        .c-container-image {
                            width: 100%;
                            height: 68px;
                            &.mod--gradient-overlay .cmp-image::after {
                                content: none;
                            }
                        }
                        .hotel-card__name {
                            font-size: 14px;
                            line-height: 16px;
                            margin-top: 5px;
                            .style-anchor;
                            display: block;
                            flex-grow: 1;
                        }
                        .hotel-card__location {
                            display: none;
                            flex-grow: 1;
                        }
                        .hotel-card__delete {
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            background-color: white;
                            width: 24px;
                            height: 24px;
                            border-radius: 24px;
                            border: 2px solid var(--b-blue);
                            .cmp-icon {
                                width: 12px;
                                height: 12px;
                                left: 50%;
                                top: 50%;
                                position: absolute;
                                transform: translate(-50%, -50%);
                                svg {
                                    fill: var(--b-blue);
                                    stroke: var(--b-blue);
                                    stroke-width: 4px;
                                }
                            }
                        }

                        .hotel-card__btn {
                            width: 100%;
                            margin: 12px auto 20px;
                            height: 24px;

                            .c-cta {
                                font-size: 14px;
                                line-height: 23px;
                                max-height: 26px;
                                padding: 0px 5px;
                                width: ~"calc(100% - 18px)";
                                font-weight: 500;

                                span {
                                    .ellipsis;
                                }
                            }
                        }
                    }
                }
            }

            .grid__content {
                .accordion-item {
                    padding: 0px;

                    .accordion-toggle {
                        margin-left: 15px;
                    }

                    &:after {
                        left: 0px;
                        width: 100%;
                        background-color: @black;
                    }
                }

                .grid__row {
                    display: flex;
                    flex-wrap: wrap;
                    width: ~"calc(100% - 30px)";
                    margin: 0px 15px;
                    align-items: center;
                    border-color: @grey-90;
                    text-align: center;
                    border-bottom-width: 1px;

                    &:first-child {
                        border-top: 1px solid @grey-90;
                        width: 100%;
                        padding: 8px 15px 0px;
                        margin: 0px;
                    }

                    &:last-child {
                        border-bottom-width: 0px;
                    }

                    .grid__title {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 500;
                        height: 25px;
                        width: 100%;
                        background: @grey-20;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        [class^="icon-"] {
                            font-size: 21px;
                            vertical-align: middle;
                            margin-left: 3px;
                        }
                    }

                    .grid__values {
                        display: flex;
                        width: 100%;

                        .grid__value {
                            min-width: 33.3%;
                            width: 100%;
                            min-height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            border-color: @grey-40;
                            text-align: center;
                            border-left-width: 1px;
                            padding: 8px;

                            &:first-child {
                                border-left-width: 0px;
                            }

                            &.mod--price {
                                color: var(--b-blue);
                                font-weight: 700;

                                .price {
                                    font-size: 28px;
                                }
                                .c-price__currency:after {
                                    font-size: 20px;
                                    line-height: 20px;
                                }
                            }

                            &.mod--social {
                                .c-tripadvisor {
                                    text-align: center;
                                    flex-flow: column;
                                    align-items: center;

                                    .ta__score {
                                        font-size: 14px;
                                        line-height: 16px;
                                    }
                                }
                            }

                            &.mod--brand {
                                img {
                                    width: 65px;
                                    height: 23px;
                                }
                            }

                            &.mod--check-price {
                                .icon-close {
                                    font-size: 20px;
                                    color: @grey-50;
                                }

                                .icon-tick {
                                    font-size: 20px;
                                    color: @b-green;
                                }
                            }

                            &.mod--room {
                                display: flex;
                                flex-wrap: wrap;
                                font-size: 12px;
                                font-weight: 500;
                                color: @grey-70;

                                .icon-login {
                                    width: 100%;
                                    font-size: 12px;
                                    color: @grey-90;
                                    font-weight: 700;
                                }

                                .room-price {
                                    display: block;
                                    width: 100%;
                                    font-weight: 700;
                                    font-size: 14px;
                                    color: @grey-90;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* HOTEL COMPARATED MAP - MOBILE ONLY */
        &__map {
            height: ~"calc(100vh - 40px)";
            display: flex;
            flex-flow: column-reverse;
        }

        .active-hidden-check {
            .c-hotels-comparated__grid .grid__content .grid__row.hidden-check {
                display: none;
            }
        }

        &.mod--not-promocode .container-promocode,
        &.mod--not-value .container-value,
        &.mod--not-discount .container-discount {
            display: none;
        }
    }
}

.offers-comparator {
    .c-hotels-comparated {
        &__grid {
            .grid__header {
                .grid__hotel-cards {
                    .grid__hotel-card {
                        .hotel-card__name {
                            text-decoration: none;
                            color: @grey-90;
                            font-weight: 500;
                            line-height: 22px;
                            cursor: auto;
                        }
                    }
                }
            }

            .grid__content {
                .grid__row {
                    .grid__values {
                        .grid__value {
                            .hotel-card__name {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 16px;
                            }
                        }
                    }

                    &.container-discount {
                        .grid__values {
                            .grid__value {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 20px;

                                span {
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }

                    &.container-argument {
                        .grid__values {
                            .grid__value {
                                display: flex;
                                flex-flow: column;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 20px;
                                justify-content: flex-start;

                                span {
                                    padding-left: 10px;
                                    position: relative;
                                    margin-bottom: 10px;

                                    &:before {
                                        font-family: "barcelo-ui-kit";
                                        content: "\e926";
                                        color: @grey-50;
                                        font-size: 10px;
                                        position: absolute;
                                        left: -2px;
                                    }
                                }
                            }
                        }
                    }

                    &.container-dates {
                        .grid__values {
                            .grid__value {
                                font-size: 12px;
                                line-height: 20px;
                                font-weight: 500;
                                display: inline;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* COMPARATOR-COMPARATED */
.hotels-comparator {
    .c-hotels-comparated {
        &__map-card {
            .c-card-hotel {
                flex-wrap: initial;
                .c-close-btn {
                    left: 15px;    
                    top: 15px;
                }
                .c-container-image {
                    height: 176px;
                }
                &__content {
                    &-footer {
                        &-price {
                            margin-bottom: 0;
                            flex-direction: row;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}


/* HOTEL COMPARATED - MOBILE ONLY */
@media (min-width: @mobile) and (max-width: @desktop) {

    .hotels-comparator,
    .offers-comparator,
    .meetings-comparator {
        .c-hotels-comparated {
            &__map {
                &-card {
                    .mod--horizontal-card;

                    .c-card-hotel__content-body-list {
                        .ellipsis-multiline(16px, 28px, 2);
                        line-height: 20px;
                    }

                    .c-card-hotel__content-header-title {}
                    
                    .c-card-hotel {
                        flex-flow: column;
                        .c-close-btn {
                            z-index: 1;
                        }                       
                        &__image {
                            width: auto;
                            .c-container-image {
                                height: 220px;
                            }
                        }
                        &__content-footer {
                            flex-wrap: nowrap;
                            justify-content: space-around;
                            &-price {
                                // margin-bottom: 10px;

                                flex-direction: row;
                                width: 100%;
                            }
                        }
                    }
                }

                &-container {
                    height: 100%;
                }
            }
        }
    }

    // COMPARED MAP CARD - HORIZONTAL COMPACT MODE
    .hotels-comparator {
        .c-hotels-comparated {
            &__map {
                &-card {
                    .c-card-hotel {
                        &.mod--horizontal-card-compact {
                            .c-card-hotel {
                               &__image {
                                    width: 38%;
                               }
                               &__content {
                                    align-items: flex-end;
                                   &-header,
                                   &-body {
                                        width: 62%;
                                        padding: 15px 15px 0px 20px;
                                   }
                                   &-body {
                                    &-list {
                                        font-size: 14px;
                                    }
                                   }
                                   &-footer {
                                    flex-wrap: nowrap;
                                    background-color: @white;
                                        .c-price-normal {
                                            // flex-direction: column;
                                        }
                                   }
                               }
                            }
                        }
                    }
                }
            }
        }
    }

}

/* HOTEL COMPARATED - DESKTOP  */
@media (min-width: @desktop) {

    .hotels-comparator,
    .offers-comparator,
    .meetings-comparator {
        .c-hotels-comparated {
            position: relative;
            left: 0px;
            z-index: 0;
            height: 100%;
            padding-bottom: 40px;
            background: transparent;

            &__header {
                &-nav {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    background: transparent;
                    box-shadow: none;
                    left: 0px;
                    margin-top: 16px;

                    &-back-btn {
                        margin: 0px 31px 0px 0px;
                    }

                    &-share-btn {
                        .c-cta {
                            .svg-icon {
                                margin-right: 6px;
                                margin-top: -3px;
                            }

                            &:hover {
                                .svg-icon {
                                    .negative-svg-icon;
                                }
                            }
                        }
                    }
                }
            }

            &__grid {
                .grid__header {
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    padding-top: 30px;
                    border-color: @grey-40;
                    border-bottom: 1px solid @grey-40;

                    .grid__filter {
                        width: 25%;
                        justify-content: flex-start;
                        padding: 0px;

                        .comparated-diferences,
                        .c-hotels-comparated__header-map-btn {
                            margin-left: 0px;
                            margin-right: 0px;
                        }
                    }

                    .grid__hotel-cards {
                        width: 75%;

                        .grid__hotel-card {
                            min-width: 33.3%;
                            border-color: @grey-40;

                            .c-container-image {
                                height: 100px;
                            }

                            .hotel-card__name {
                                font-size: 16px;
                                line-height: 24px;
                                padding: 0px 32px;
                                position: relative;

                                .hotel-card__delete {
                                    position: absolute;
                                    top: 0;
                                    right: 8px;
                                    cursor: pointer;
                                    background: transparent;
                                    border-radius: 0px;
                                    border: 0px;

                                    .cmp-icon {
                                        width: 16px;
                                        height: 16px;

                                        svg {
                                            stroke-width: 1px;
                                        }
                                    }
                                }
                            }

                            .hotel-card__location {
                                display: block;
                                font-size: 14px;
                                line-height: 14px;
                            }

                            .hotel-card__btn {
                                .c-cta {
                                    max-height: 32px;
                                    height: 32px;
                                    font-size: 16px;
                                    // line-height: 36px;
                                    line-height: 30px;
                                    width: ~"calc(100% - 26px)";

                                    span {
                                        height: 32px;
                                        line-height: 32px;
                                    }
                                }
                            }
                        }
                    }
                }

                .grid__content {
                    .accordion-item {
                        .accordion-toggle {
                            margin-left: 0px;
                        }

                        &:after {
                            background-color: @grey-40;
                        }
                    }

                    .grid__row {
                        flex-wrap: nowrap;
                        padding: 0px;
                        margin: 0px;
                        width: 100%;

                        &:first-child {
                            border-top: 1px solid @grey-40;
                            padding: 0px;
                        }

                        .grid__title {
                            font-size: 16px;
                            min-width: 25%;
                            height: 50px;
                            justify-content: flex-start;

                            [class^="icon-"] {
                                font-weight: 500;
                            }
                        }

                        .grid__values {
                            min-width: 75%;
                            border-right: 1px solid @grey-40;

                            .grid__value {
                                &:first-child {
                                    border-left-width: 1px;
                                }

                                &.mod--price {
                                    .price,
                                    .c-price__currency {
                                        // font-size: 36px;
                                    }

                                    // .currency {
                                    //     font-size: 20px;
                                    // }
                                }

                                &.mod--social {
                                    .c-tripadvisor {
                                        flex-flow: row;
                                        justify-content: center;

                                        .ta__score {
                                            font-size: 16px;
                                            margin-right: 8px;
                                        }
                                    }
                                }

                                &.mod--room {
                                    flex-wrap: nowrap;
                                    justify-content: space-around;
                                    font-size: 14px;

                                    .icon-login {
                                        width: auto;
                                        font-size: 16px;
                                    }

                                    .room-price {
                                        display: inline;
                                        margin-right: 0.25rem;
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /* HOTEL COMPARATED MAP - DESKTOP */
            &__map {
                height: inherit;
                margin-top: 40px;
                flex-flow: row;

                &-card {
                    .c-card-hotel {
                        height: 545px;
                        width: 360px;

                        // .c-container-image {
                        //     height: 200px;
                        // }
                        .c-container-image {
                            height: 176px;
                        }

                        &__content {
                            &-header {
                                padding: 10px 15px 0px;

                                &-title {
                                    // height: 58px;
                                    height: auto;
                                }
                            }

                            &-body {
                                padding: 10px 15px 0px;

                                hr {
                                    margin: 4px 0px;
                                }

                                &-list {
                                    .ellipsis-multiline(16px, 28px, 2);
                                }
                            }

                            &-footer {
                                padding: 5px 25px 10px;
                                margin-top: 0px;

                                display: flex;

                                &-price {
                                    // flex-direction: column;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }

                &-container {
                    height: 545px;
                }
            }
        }
    }

    .offers-comparator {
        .c-hotels-comparated {
            &__grid {
                .grid__content {
                    .grid__row {
                        .grid__values {
                            .grid__value {
                                .hotel-card__name {
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                            }
                        }

                        &.container-discount {
                            .grid__values {
                                .grid__value {
                                    font-size: 14px;

                                    span {
                                        font-size: 18px;
                                        line-height: 24px;
                                    }
                                }
                            }
                        }

                        &.container-argument {
                            .grid__values {
                                .grid__value {
                                    font-size: 14px;
                                }
                            }
                        }

                        &.container-dates {
                            .grid__values {
                                .grid__value {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}