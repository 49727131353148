//	 __          __              _             _ 
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |  
//	                                     |___/   
//	
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

/* BADGES - MOBILE FIRST (~ TABLET) */

.c-ballon {
    border-radius: 12px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: @grey-70;
    display: inline-block;
    padding: 2px 9px;
    text-align: center;
    width: auto;
    min-width: 32px;
    background: @grey-20;
    border-radius: 12px;
    margin: 2px 0.25rem 2px 0.25rem;
    &::first-letter {
        text-transform: uppercase;
    }
    &.mod--blue {
        color: var(--b-blue);
    }
}

.c-adv {
    display: flex;
    align-items: center;
    border-radius: @soft-corners;
    background: @b-light-blue;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    padding: 16px;
    &__title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
    }
    &__message {
        flex-shrink: 1;
    }
}

/* BADGES - DESKTOP */
@media (min-width: @desktop) {
    .c-adv {
        display: flex;
        align-items: center;
        border-radius: @soft-corners;
        background: @b-light-blue;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
        padding: 16px;
        &__title {
            font-size: 20px;
            line-height: 28px;
            display: block;
            margin-bottom: 5px;
        }
    }
}
