.mod--horizontal-card {
    .c-card-hotel {
        flex-wrap: nowrap;
        flex-flow: row;
        box-shadow: 0px 1px 2px 0px fade(@black, 30%);

        &__image {
            width: 263px;
            flex-shrink: 0;

            .c-container-image {
                height: 100%;
            }
        }

        &__content {
            width: 100%;
            position: relative;

            &-header {
                padding: 15px 25px 0px 35px;
                flex-grow: 0;

                &-title {
                    .c-cta {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }

                &-location {
                    font-size: 14px;
                    margin-top: 4px;
                }
            }

            &-body {
                padding: 10px 25px 10px 35px;
                flex-grow: 0;

                &-info {
                    height: auto;

                    .c-tripadvisor {
                        .ta__score {
                            font-size: 14px;
                        }

                        .ta__reviews {
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }

                &-list {
                    font-size: 14px;
                    line-height: 18px;
                    -webkit-line-clamp: unset;
                    -webkit-box-orient: unset;
                    overflow: unset;
                    text-overflow: inherit;
                    max-height: 100%;
                }
            }

            &-not-available {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding: 10px 25px 10px 35px;
            }

            &-footer {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding: 10px 25px 10px 35px;

                &-price {
                    flex-flow: row;
                    margin: 0px;

                    &-claim {
                        font-size: 14px;
                        margin-bottom: 0px;
                    }

                    .c-price {

                        &__currency,
                        &__type {
                            // font-size: 16px;
                            // font-weight: 500;
                        }
                    }
                }

                &.mod--hotel-my-barcelo {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                .mod--cta-full-width {
                    max-width: 250px;

                    .c-cta {
                        margin: 0px auto;
                    }
                }
            }
        }

        &.c-card-hotel-offer {
            .c-card-coupon {
                &__promo {
                    &-discount {
                        justify-content: space-between;
                        flex-wrap: nowrap;

                        .c-offer {
                            max-width: 245px;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}