/* MODIFIERS */
[class^="mod--size-"],
[class*=" mod--size-"] {
  flex-shrink: 0;
}

.mod--size-8 {
  width: 8px;
  height: 8px;
}
.mod--size-12 {
  width: 12px;
  height: 12px;
}
.mod--size-14 {
  width: 14px;
  height: 14px;
}
.mod--size-16 {
  width: 16px;
  height: 16px;
}
.mod--size-18 {
  width: 18px;
  height: 18px;
}

.mod--size-20 {
  width: 20px;
  height: 20px;
}
.mod--size-24 {
  width: 24px;
  height: 24px;
}
.mod--size-28 {
  width: 28px;
  height: 28px;
}
.mod--size-32 {
  width: 32px;
  height: 32px;
}
.mod--size-38 {
  width: 38px;
  height: 38px;
}
.mod--size-40 {
  width: 40px;
  height: 40px;
}
.mod--size-42 {
  width: 42px;
  height: 42px;
}
.mod--size-48 {
  width: 48px;
  height: 48px;
}
.mod--size-56 {
  width: 56px;
  height: 56px;
}
.mod--size-72 {
  width: 72px;
  height: 72px;
}
.mod--size-80 {
  width: 80px;
  height: 80px;
}

@media (min-width: @desktop) {
  .mod--size-16-d {
    width: 16px;
    height: 16px;
  }
  .mod--size-18-d {
    width: 18px;
    height: 18px;
  }
  .mod--size-20-d {
    width: 20px;
    height: 20px;
  }
  .mod--size-24-d {
    width: 24px;
    height: 24px;
  }
  .mod--size-32-d {
    width: 32px;
    height: 32px;
  }
  .mod--size-40-d {
    width: 40px;
    height: 40px;
  }
  .mod--size-42-d {
    width: 42px;
    height: 42px;
  }
  .mod--size-48-d {
    width: 48px;
    height: 48px;
  }
  .mod--size-72-d {
    width: 72px;
    height: 72px;
  }
  .mod--size-80-d {
    width: 80px;
    height: 80px;
  }
}
