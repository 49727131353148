:root {
  --b-aquamarine-dark-blue: rgb(49, 89, 115);
  --b-dark-blue-gradient: linear-gradient(90deg, rgb(48, 162, 191) 0%, rgb(47, 90, 118) 100%);
  --b-aquamarine-blue: rgb(48, 162, 191); // #b-aquamarine-blue
  --b-dark-blue-gradient-hover: linear-gradient(90deg, rgb(38, 130, 153) 0%, rgb(38, 72, 94) 100%, rgb(38, 72, 94) 100%);
  --b-dark-blue-gradient-active: linear-gradient(90deg, rgb(29, 97, 115) 0%, rgb(28, 54, 71) 100%, rgb(28, 54, 71) 100%);
  --b-blue: rgb(21, 85, 131);
}

// COLOR

// Neutral
@white: rgb(255, 255, 255); //#white
@black: rgb(0, 0, 0); //#dark_black
@transparent: transparent;

// Greys Palette | The number identifies the degree of intensity of the gray
@grey-90: rgb(56, 64, 68); // #black #384044
@grey-80: rgb(79, 84, 87); // #gunmetal
@grey-75: rgb(96 102 105); // #606669
@grey-70: rgb(118, 118, 118); // #text_grey
@grey-50: rgb(140, 144, 145); // #barcelo_grey
@grey-48: rgb(151, 151, 151); // #979797 - Borders
@grey-45: rgb(177, 177, 177); // #greyfish
@grey-44: rgb(175, 179, 180); // #disabled cta textcolor  #AFB3B4
@grey-42: rgb(204, 204, 204); // #pinkish-grey
@grey-40: rgb(216, 216, 216); // #hr_grey
@grey-38: rgb(215, 217, 218); // #fb grey #D7D9DA
@grey-30: rgb(224, 224, 224); // #medium_grey
@grey-25: rgb(234, 238, 241); // #outlined dark-blue bgcolor #EAEEF1
@grey-20: rgb(245, 245, 245); // #background-grey #F5F5F5
@grey-10: rgb(241, 241, 241); // #white-two

// Color Palette Barcelò
@b-link-hover: rgb(0, 121, 207); //#cerulean
@b-light-dusty-blue: rgb(130, 156, 173);// #disabled secundary inversed cta textcolor #829CAD
@b-dusty-blue: rgb(93, 139, 173); // #dusty-blue
@b-blue: rgb(21, 85, 131); // #dark_blue
@b-light-blue: rgb(197, 232, 243); // #light_blue #blue_background
@b-intense-blue: rgb(0, 121, 207); // #intense-blue
@b-green: rgb(43, 133, 54); // #green
@b-light-green: rgb(221, 236, 212); // #light-green
@b-brown: rgb(150, 105, 76); // #hideway_brown
@b-red: rgb(204, 71, 22); // #dark_orange
@b-pale-yellow: rgb(255, 239, 171); // #pale-peach
@b-pumpking: rgb(239, 122, 0); //#pumpking
@b-yellow-orange: rgb(255, 178, 0); // #yellow-orange
@b-yellow: rgb(245, 241, 214); // #light_yellow
@b-dark-yellow: rgb(216, 206, 141); // said
@b-mallow: rgb(179, 108, 145); //#mauve
@b-magenta: rgb(232, 31, 118); // #allegro_magenta
@b-occ-blue: rgb(75, 133, 142); // #occidental_blue
@b-wecare: rgb(75, 133, 142); // #Wecare_blue

@b-ocean15: rgb(224, 230, 234); //#E0E6EA #ocean-15
@ocean-70: rgb(47, 90, 118); //#2F5A76

@b-aquamarine-blue: var(--b-aquamarine-blue);
@b-aquamarine-dark-blue: rgb(49, 89, 115); // #b-aquamarine-blue-dark

@b-dark-blue-gradient: var(--b-dark-blue-gradient); //(90deg, #30A2BF 0%, #2F5A76 100%)

@b-dark-blue: rgb(47, 90, 118); //#2F5A76
@b-dark-blue-gradient-hover: var(--b-dark-blue-gradient-hover);
@b-dark-blue-gradient-active: var(--b-dark-blue-gradient-active);
@b-blue-gradient-focused: 0px 0px 2px 1px rgb(26, 188, 254);// #1ABCFE;
@b-lavender: rgb(166, 185, 250); // #lavender

@tripadv: rgb(0, 166, 128); // #tripadvisor
@white-montecastillo: rgb(229, 236, 233); //Blanco Montecastillo
@green-montecastillo: rgb(0, 66, 36); //Verde Montecastillo
@brown-royal: rgb(153, 105, 76); //Marrón Intenso Royal
@black-royal: rgb(60, 60, 60); //Negro fondo Royal Level #3C3C3C
@black-txt-royal: rgb(64, 63, 62); //Negro fondo Royal Level #403F3E
@brown-light-royal: rgb(237, 227, 221); //Marrón Light Royal
@grey-royal: rgb(245, 245, 245); //Gris Light Royal
@brown-classic: rgb(221, 202, 193); //Marrón Classics
@green-correct: rgb(25, 118, 37); //#green-correct
@red-error: rgb(186, 34, 34); //#red-error
@red-error-bright: rgb(228, 15, 19); //#red-error-2 #E40F13
@red-error-dark: rgb(150 11 13); //#red-error-3 #960b0d
@green-calendar: rgb(164, 200, 169);

// MyBarcelo Palette
@myb-standard: fade(@b-dark-yellow, 15%);
@myb-intense: fade(@b-mallow, 15%);
@myb-unique: fade(@black, 15%);

@body-bg: @white;

@myb-yellow: rgb(249, 248, 238);
@myb-light-blue:rgb(223, 232, 239);

@gold-royal:rgb(212, 200, 178);// Royal Level golden details #D4C8B2

// Resorts Palette
@br-sand-60: #F5ECD6; 

//EDIT
@green-edit: rgb(173 255 47); // #adff2f Edit mode borders
@green-off-edit: rgba(173, 255, 47, 0.2);
@semi-transparent: rgba(255, 255, 255, 0.7);
@off-edit-opacity: 0.4;
// @edit-pattern: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/transparent-bg.svg");

// TEXT VARIABLES
@text-color: @grey-90;
@font-family-base: "Museo Sans", Helvetica, Arial, sans-serif;
@html-font-size: 16px; // all the rems are calculated by 16px
@font-size-base: 1rem; // 16px
@line-height-base: 1.5rem;
@small-text-base: 12px;

// SIZES AND POSITIONS
@input-height: 40px;
@header-mobile-height: 63px;
// @header-desktop-height: 95px;
@header-desktop-height: 80px;
@ifb-mobile-position-full: -35vh; // ifb ~ inject fastbooking
@ifb-mobile-position-md: -60px;
@ifb-mobile-position-sm: -30px;
@ifb-desktop-height: 128px;
@ifb-desktop-position: 80px;
@soft-corners: 2px;
@medium-corners: 8px;
@rounded-corners: 16px;
@circle: 99999px;

// BREAKPOINT
@new-content-sizing: 1312px;
@content-sizing: 1140px;
@desktop: 1025px;
@mobile: 320px;

// MYB INFO ICON
@info-size: 15px;