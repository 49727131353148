/* DETAIL OFFER - MOBILE FIRST (~ TABLET) */
.c-detail-offer {
    width: 100%;

    &__header {
        position: fixed;
        width: 100%;
        background: white;
        .z-sticky;

        .c-back-bar {
            display: flex;
            justify-content: space-between;
            max-width: @content-sizing;
            margin: auto;
            height: 26px;
            overflow: hidden;

            .c-pagination {
                position: absolute;
                right: 15px;
            }

            .cmp-title {
                position: absolute;
                top: 35px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;

                .cmp-title__text {
                    font-size: 14px;
                }
            }
        }

        .c-header-detail {
            &__nav {
                display: flex;
                height: 112px;
                align-items: center;
                overflow: hidden;
                padding: 20px 0px;

                &-title {
                    .ellipsis-multiline(20px, 24px, 2);
                    min-width: 75%;
                }
            }
        }
    }

    &__content {
        padding-top: 138px;
        display: flex;
        flex-flow: column-reverse;
        justify-content: center;
        align-items: center;

        &-info {
            text-align: left;
            font-size: 20px;
            line-height: 32px;
            padding: 0px 15px;

            .cmp-title {
                text-align: center;
                margin: 15px 0px 12px;
            }

            .c-card-dates {
                font-size: 16px;
                line-height: 20px;
                margin: 15px 0px;

                display: flex;
                flex-direction: column;

                &__alert {
                    margin-left: auto;
                    margin-right: auto;
                }

                &__container {
                    background: transparent;

                    .c-card-dates__periods-date {
                        margin: 0 auto;
                    }
                }
            }

            ul {
                li {
                    margin-bottom: 1em;
                }
            }
        }

        &-card {
            width: 100%;
            position: relative;
            margin-bottom: 50px;

            &-image {
                position: relative;

                .c-container-image {
                    width: 100%;
                    height: 250px;
                }

                .cmp-icon {
                    svg {
                        path,
                        circle {
                            stroke: inherit;
                            stroke-width: inherit;
                        }
                    }
                }
            }

            &-footer {
                position: absolute;
                width: ~"calc(100% - 30px)";
                left: 15px;
                bottom: -15px;
                .z-layer;
                background-color: @white;
                border-radius: 2px;
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 4px 8px;

                .c-label {
                    position: relative;
                    font-size: 18px;
                    font-weight: 700;
                    padding: 4px 10px;
                    margin-right: 15px;
                }

                .c-offer {
                    margin-right: 7px;
                }

                button {
                    overflow: hidden;
                }
            }
        }
        //offers collection list
        &.c-card-hotel-offer {
            .c-detail-offer__content-card {
                &-footer {
                    &.c-card-coupon__promo-discount {
                        justify-content: center;
                        .c-offer {
                            border-radius: 8px;
                            background-color: #d9e8db;
                            color: @grey-90;
                            padding: 8px 40px;
                            .c-card-dates__periods-date-claim {
                                align-items: center;
                                display: flex;
                                font-weight: 700;
                                .svg-icon {
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 8px;
                                }
                                .c-card-hotel__content-footer-discount-claim {
                                    margin-right: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: .08px;
                                }
                                .c-card-hotel__content-footer-discount-value {
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: .08px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__policies {
        display: flex;
        flex-flow: column;

        &-item {
            margin: 15px;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;

            &-title {
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                margin-bottom: 15px;
            }
        }
    }

    .promo-info {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        &__item {
            width: 100%;
            display: flex;
            padding: 0px 15px 30px;

            .icon {
                margin-right: 0.9rem;
            }
        }
    }

    &.paginated { //offer detail 
        .c-detail-offer__content {
            &.c-card-hotel-offer {
                .c-detail-offer__content-card-footer {
                    &.c-card-coupon__promo-discount {
                        padding: 12px 10px 4px;
                        .c-offer {
                            max-width: none;
                            padding: 8px 4px;

                            .c-card-hotel__content-footer-price-claim {
                                margin-left: 10px;
                            }
                            .c-price {
                                &__value {
                                    font-size: 20px;
                                }
                                &__currency, &__type {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* DETAIL OFFER - DESKTOP  */
@media (min-width: @desktop) {
    .c-detail-offer {
        &__header {
            .c-back-bar {
                display: flex;

                .c-pagination {
                    position: absolute;
                    left: 50%;
                    right: auto;
                    transform: translate(-50%);
                }

                .cmp-title {
                    position: relative;
                    top: auto;
                    left: auto;
                    transform: none;
                    width: auto;
                    max-width: 40%;

                    &__text {
                        .ellipsis;
                    }
                }
            }

            .c-header-detail {
                &__nav {
                    &-title {
                        .ellipsis-multiline(24px, 36px, 2);
                        min-width: 60%;
                    }
                }
            }

            .c-fastbooking {
                .c-back-bar {
                    display: none;
                }
            }
        }

        &__fastbooking-container {
            display: block;
            // height: 58px;
            height: 66px;
            width: 100%;
            border-top: 1px solid @grey-40;
            border-bottom: 1px solid @grey-40;
            background: @grey-20;
        }

        &__content {
            padding-top: 195px;
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-start;
            margin: 30px auto;

            &-info {
                max-width: 555px;

                .cmp-title {
                    text-align: left;
                }

                .c-card-dates {
                    font-size: 20px;
                    line-height: 24px;
                    display: block;

                    &__alert {
                        margin-left: 0;
                        margin-right: 0;
                        margin-bottom: 8px;
                    }

                    &__container {

                        .c-card-dates__periods-date {
                            margin: 0;
                        }
                    }
                }
            }

            &-card {
                max-width: 458px;
                overflow: hidden;
                border-radius: 2px;
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);

                &-image {
                    .c-container-image {
                        height: 350px;
                    }
                }

                &-footer {
                    width: 100%;
                    padding: 10px;
                    position: relative;
                    left: auto;
                    bottom: auto;
                    border-radius: 0px;
                    box-shadow: none;
                    margin: 0px;
                    display: flex;

                    .c-offer {
                        max-width: 150px;
                    }

                    .c-label {
                        font-size: 20px;
                    }
                }
            }
        }

        &__policies {
            flex-flow: row;
            margin: 0px 15px;

            &-item {
                margin: 50px 15px;
                font-size: 18px;
                line-height: 28px;

                &-title {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }

        .promo-info {
            &__item {
                padding: 0px;
                width: 50%;

                &:first-child {
                    padding-right: 30px;
                }
            }
        }

        &.paginated { //offer detail 
            .c-detail-offer__content {
                &.c-card-hotel-offer {
                    .c-detail-offer__content-card-footer {
                        &.c-card-coupon__promo-discount {
                            .c-offer {
                                .c-price {
                                    &__value {
                                        font-size: 24px;
                                    }
                                    &__currency, &__type {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fastbooking-on-detail-offer {
        .fastbooking {
            .c-fastbooking {
                left: 0px;
                top: 0px;
                background: @grey-20;
                max-width: @content-sizing;
                margin: auto;
                box-shadow: none;
            }
        }
    }
}

/* DETAIL OFFER  - DESKTOP CONTENT - SIZE */
@media (min-width: @desktop) and (max-width: @content-sizing) {
    .c-detail-offer {
        &__header {
            padding-right: 16px;

            .c-header-detail {
                &__nav {
                    padding: 15px;
                }
            }
        }
    }

    .fastbooking-on-detail-offer {
        .fastbooking {
            .c-fastbooking {
                padding: 0px 17px 0px 0px;
                width: ~"calc(100vw - 40px)";
            }
        }
    }
}