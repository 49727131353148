/* COMPARATOR - MOBILE FIRST (~ TABLET) */
/*------------ Dependencies ------------------------------------------------*/
@import (once) "../../../../../clientlibs/base/less/inc/common/card-hotel-horizontal.less";
@import (once) "../../../../../clientlibs/base/less/inc/common/badges.less";
@import (once) "../../../../../clientlibs/base/less/inc/common/modifiers_min.less";

/*-------------------------------------------------------------------------------------*/
.hotels-comparator,
.offers-comparator {
  .break-container;
  .c-hotels-comparator {
    display: flex;
    flex-wrap: wrap;
    &__body {
      width: 100%;
      .body {
        &__main-nav {
          display: flex;
          width: 100%;
          align-items: center;
          overflow: auto;
          font-weight: 500;
          background: @grey-20;
          padding: 0px 10px;
          font-size: 14px;
          margin-top: 10px;
          height: 40px;
          .main-nav {
            &__hotels-btn {
              flex-shrink: 0;
              white-space: nowrap;
              display: flex;
              flex-wrap: nowrap;
              height: 100%;
              justify-content: center;
              align-items: center;
              padding: 0px 5px;
              flex-grow: 1;
              // line-height: normal;
              border-right: 1px solid @grey-45;
              flex-basis: 50%;
              min-height: 40px;
              .svg-icon {
                .mod--size-16;
                margin-right: 0.5em;
                filter: brightness(0) saturate(100%) invert(23%) sepia(70%) saturate(840%) hue-rotate(169deg) brightness(102%) contrast(93%);
              }
              &__hotels {
                border-right: 0;
                background: @grey-50;
                width: auto;
                min-width: initial;
                flex: none;
                padding: 6px 10px;
                margin-right: 8px;
                color: @white;
                font-weight: 500;
              }
              .mobile-btn {
                margin-right: 3px;
                text-decoration: underline;
                color: var(--b-blue);
              }
            }
            &__select {
              white-space: nowrap;
              height: 100%;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              padding: 0px 5px;

              text-decoration: underline;
              color: var(--b-blue);
              border-right: 1px solid @grey-45;
              font-size: 14px;
              flex-basis: 25%;
              min-height: 40px;
              label {
                padding: 0px;
                margin-bottom: 0px;
                font-weight: 500;
                font-size: 14px;
                text-decoration: underline;
                color: var(--b-blue);
              }
              .input-order {
                max-width: 200px;
                width: 76%;
                opacity: 0;
                position: absolute;
                height: 100%;
                margin: 0px;
                padding: 0px;
                .z-layout;
              }
              .svg-icon {
                .mod--size-16;
                margin-right: 0.5em;
                filter: brightness(0) saturate(100%) invert(23%) sepia(70%) saturate(840%) hue-rotate(169deg) brightness(102%) contrast(93%);
              }
            }
          }
          .comparator-btn {
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0px 5px;
            text-align: center;
            color: var(--b-blue);
            font-size: 14px;
            flex-basis: 25%;
            min-height: 40px;
            &-block {
              text-decoration: underline;
              margin-bottom: 0;
            }
            &.mod--disabled {
              opacity: 0.6;
              text-decoration: none;
              cursor: not-allowed;
            }
          }
          .mode-btn {
            min-width: 19%;
            white-space: nowrap;
            display: none;
            height: 100%;
            justify-content: center;
            align-items: center;
            padding: 0px 5px;
            text-align: center;

            .common-transition;
            position: fixed;
            display: flex;
            bottom: 24px;
            transform: translate(-50%, -3vh);
            left: 50%;
            background: #384044;
            color: @white;
            height: 40px;
            border-radius: 27px;
            padding: 0 20px;
            min-width: 128px;
            font-size: 16px;
            opacity: 0;
            visibility: hidden;

            .map-btn-block,
            .list-btn-block {
              .svg-icon {
                .mod--size-16;
              }
            }
          }
          &.mod--stick-top {
            margin-top: 0px;
            height: 48px;
            .mode-btn {
              .z-sticky;
              opacity: 1;
              visibility: visible;

              .map-btn-block,
              .list-btn-block {
                .svg-icon {
                  .mod--size-24;
                  filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(7%) hue-rotate(106deg) brightness(101%) contrast(105%);
                }
              }
              .list-btn-block {
                .svg-icon {
                  .mod--size-16;
                }
              }
            }
          }
          &.mod--view-map {
            box-shadow: 0px 2px 3px 0px fade(@black, 30%);
            margin-top: 0;
            .main-nav__hotels-btn {
              border-right: 0;
              justify-content: space-around;
              position: relative;
              min-height: initial;
              &__hotels {
                background: initial;
                color: initial;
              }
              &__filter {
                width: 97px;
                text-align: center;
              }
              &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 40px;
                transform: translateX(-50%);
                left: 50%;
                background: @grey-45;
              }
            }
          }
        }
        &__result {
          .result {
            &__map {
              &-container {
                width: 100%;
                height: 700px;
              }
            }
            &__list {
              padding: 0px 15px;
              &-item {
                padding: 10px 0px;

                .c-close-btn {
                  display: none;
                }
                .not-available {
                  display: inline-flex;
                  .cmp-icon {
                    margin-right: 10px;
                  }
                }
                &.mod--hidePagination {
                  display: none;
                }
                &.mod--highlighted-card {
                  filter: drop-shadow(4px 4px 24px rgba(0, 130, 155, 0.4));
                  .mod--highlighted-card__label {
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 0px 4px 4px 0px;
                    left: 0px;
                    right: initial;
                    top: 22px;
                  }
                }
              }
              &.mod--package {
                display: flex;
                flex-wrap: wrap;
                .result__list-item {
                  width: 100%;
                  margin: 16px 0px;
                  padding: 0px;
                  .c-flip-card {
                    width: 100%;
                    margin: 0px auto;
                  }
                }
              }
            }
            &__comparator-bar {
              display: none;
              width: 100%;
              padding-right: 16px;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              height: 35px;
              font-weight: 700;
              background: @grey-40;
              color: @grey-70;
              cursor: pointer;
              &:after {
                font-family: "barcelo-ui-kit";
                content: "\e926";
                color: @grey-40;
                border-radius: @circle;
                background: @grey-20;
                width: 24px;
                height: 24px;
                margin-left: 8px;
                text-align: center;
                font-weight: 300;
                font-size: 14px;
                line-height: 24px;
              }
              &.active {
                background: var(--b-blue);
                color: @white;
                &:after {
                  color: var(--b-blue);
                  background: @white;
                }
              }
            }
          }
        }
      }
    }
    .c-hotels-comparator__body {
      .applicated-filters__container {
        // padding-top: 2px;
        padding: 8px 15px;
      }
    }
    .applicated-filters__container,
    .c-hotels-comparator__body {
      .applicated-filters__filtered-item {
        max-width: 100%;
        background: @white;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        border-radius: 12px;
        height: 24px;
        display: inline-flex;
        padding: 3px 8px;
        margin: 0px 10px 10px 0px;
        color: var(--b-blue);
        transition: all 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        span {
          width: 100%;
          padding-right: 30px;
          .ellipsis;
        }
      }
      .applicated-filters__delete-filtered {
        position: absolute;
        background: @white;
        right: 0px;
        width: 30px;
        text-align: center;
        color: var(--b-blue);
        margin-left: 10px;
        font-size: 12px;
        cursor: pointer;
        &:before {
          font-family: "barcelo-ui-kit";
          content: "\e909";
        }
      }
    }
    &__sidebar {
      background: @grey-20;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0px;
      transition: all 0.5s ease-out;
      left: -102%;
      overflow: auto;
      .z-modal;
      .sidebar {
        &__header {
          position: fixed;
          .z-navs;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: @white;
          box-shadow: 0px 1px 2px 0px fade(@black, 30%);
          padding: 0px 15px;
          transition: all 0.5s ease-out;
          left: -102%;
          .sidebar__close-btn {
            .style-anchor;
          }
          .sidebar__title {
            font-size: 12px;
            font-weight: 500;
            color: @grey-70;
          }
        }
        hr {
          display: none;
        }
        &__applicated-filters {
          height: auto;
          display: none;
          transition: all 0.5s ease-out;
          .applicated-filters {
            &__header {
              display: flex;
              justify-content: space-between;
            }
            &__empty-btn {
              .style-anchor;
            }
            &__container {
              padding: 16px 0px;
            }
            &__footer {
              .style-anchor;
              font-size: 14px;
            }
          }
        }
        &__facets-list {
          padding: 14px 14px 64px 14px;
          .accordion-item {
            &:after {
              left: 0px;
              width: 100%;
            }
            .accordion-toggle {
              width: 100%;
              font-weight: 700;
              &:before {
                position: absolute;
                right: 0px;
              }
            }
            .accordion-content {
              padding-top: 5px;
              .facet {
                margin-bottom: 15px;
                label {
                  font-weight: 300;
                }
                .count {
                  .c-ballon;
                  background: @white;
                }
                &-tab-1 {
                  margin-left: 25px;
                }
                &-tab-2 {
                  margin-left: 50px;
                }
                &-tab-3 {
                  margin-left: 75px;
                }
                &-tab-4 {
                  margin-left: 100px;
                }
                &-tab-5 {
                  margin-left: 125px;
                }
              }
            }
          }
          .facets-list {
            &__toggle-btn {
              .style-anchor;
              font-size: 14px;
            }
            &__show-more {
              display: block;
            }
            &__show-less {
              display: none;
            }
          }
        }
        &__facets-list .dimension,
        &__applicated-filters {
          .facets-list__toggle-btn {
            display: none;
          }
          &.moreOrLess {
            .facets-list__toggle-btn {
              display: block;
            }

            .facets-list__show-less {
              display: none;
            }

            .applicated-filters__filtered-item:nth-child(n + 6) {
              display: none;
            }
          }
          &.moreOrLessActive {
            .facets-list__show-less {
              display: block;
            }

            .facets-list__show-more {
              display: none;
            }

            .applicated-filters__filtered-item:nth-child(n + 6) {
              display: inline-flex;
            }
          }
        }
        &__facets-list .dimension {
          &.moreOrLess {
            .facets-list__show-more,
            .facets-list__show-less {
              display: none;
            }
          }
          &.moreOrLessActive {
            .facets-list__show-less,
            .facets-list__show-more {
              display: none;
            }
          }
          &.moreOrLess.active {
            .facets-list__show-more {
              display: block;
            }

            .facets-list__show-less {
              display: none;
            }
          }
          &.moreOrLessActive.active {
            .facets-list__show-less {
              display: block;
            }

            .facets-list__show-more {
              display: none;
            }
          }
        }
        &__footer {
          position: fixed;
          .z-navs;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: space-between;
          background: @white;
          box-shadow: 0px -1px 2px 0px fade(@black, 30%);
          padding: 4px 15px;
          transition: all 0.5s ease-out;
          left: -102%;

          .mode-btn {
            display: none;
            .list-btn-block {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      &.open {
        left: 0px;
        .sidebar {
          &__header,
          &__footer {
            left: 0px;
          }
        }
      }
    }
    &.active-comparator {
      .c-hotels-comparator__body {
        .comparator-btn-block {
          background: var(--b-blue);
          color: @white;
          border: 1px solid var(--b-blue);
          padding: 6px 10px;
        }
      }
      .c-hotels-comparator__sidebar {
        .comparator-btn {
          // background: var(--b-blue);
          // color: @white;
          // border: 1px solid var(--b-blue);
          // text-decoration: none;
        }
      }
      .c-hotels-comparator__comparing {
        display: block;
      }
      .body__result {
        .result__comparator-bar {
          display: flex;
        }
        .bannerCardSurprise {
          .result__comparator-bar {
            display: none;
          }
        }
        .result__list-item {
          &.mod--highlighted-card {
            .mod--highlighted-card__label {
              top: 54px;
            }
          }
        }
      }
    }

    .mod--iaTemplate {
      width: ~"calc(100% - 30px)";
      margin: 0 auto;
      max-width: 1307px;
    }
  }
}

/* COMPARATOR - DESKTOP */
@media (min-width: @desktop) {
  .hotels-comparator,
  .offers-comparator,
  .meetings-comparator {
    .default-container;
    .c-hotels-comparator {
      padding: 40px 0px;
      &__body {
        width: ~"calc(75% - 32px)";
        margin-left: 32px;
        .body {
          &__main-nav {
            overflow: hidden;
            height: auto;
            border-radius: @soft-corners;
            font-size: 16px;
            font-weight: 300;
            box-shadow: none;
            margin-top: 0px;
            min-height: 41px;
            background: transparent;
            padding: 0;
            .main-nav {
              &__hotels-btn {
                flex-wrap: wrap;
                white-space: normal;
                font-size: 20px;
                justify-content: flex-start;
                word-break: break-all;
                .border-height-hack(2em);
                border-right: 0;
                width: auto;
                min-width: initial;
                flex: none;
                padding-right: 0;
                padding-left: 0;
                color: @white;
                font-weight: 500;
                &__hotels {
                  margin-right: 0px;
                  min-width: 137px;
                  text-align: center;
                }
              }
              &__select {
                white-space: normal;
                padding-top: 16px;
                padding: 0px 15px;
                .border-height-hack(2em);
                width: initial;
                flex: 2;
                border-right: 0;
                text-decoration: none;
                font-size: 16px;
                max-width: 240px;
                label {
                  font-size: 16px;
                  font-weight: 300;
                  padding: 0px 10px 0 0;

                  display: none;
                }
                .input-order {
                  position: relative;
                  height: 40px;
                  padding: 0px 25px 0px 0.5rem;
                  opacity: 1;
                  width: 100%;
                  max-width: none;
                }
              }
            }
            .comparator-btn,
            .mode-btn {
              word-break: break-all;
              min-width: inherit;
              padding: 0px 15px;
              justify-content: center;
              .border-height-hack(2em);
              .style-anchor;
              text-decoration: none;
            }
            .comparator-btn {
              width: initial;
              flex: 1;
              border-right: 0;
              padding-left: 0;
              &-block {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                text-decoration: none;
              }
            }
            .mode-btn {
              width: initial;
              flex: 1.3;
              font-weight: 500;
              justify-content: right;
              opacity: 1;
              visibility: visible;
              position: relative;
              display: flex;
              bottom: initial;
              transform: none;
              left: initial;
              color: black;
              background: transparent;
              min-width: initial;
              flex: 1;
              &.list-btn {
                width: auto;
              }
              &_text {
                text-decoration: underline;
              }
              .map-btn-block,
              .list-btn-block {
                .svg-icon {
                  .mod--size-24;
                  width: 24px;
                  margin-right: 0.5em;
                }
              }
            }
            &.mod--view-map {
              box-shadow: none;
              margin-top: 0;
              .main-nav__hotels-btn {
                border-right: 0;
                justify-content: space-around;
                position: relative;
                min-height: initial;
                &__hotels {
                  background: @grey-50;
                  color: @white;
                }
                &__fil&__listter {
                  width: 97px;
                  text-align: center;
                }
                &::after {
                  content: none;
                }
              }
            }
          }
          &__result {
            .result {
              &__list {
                padding: 16px 0px 0px;
                .mod--horizontal-card;
                padding-top: 24px;
                &-item {
                  .c-card-hotel {
                    .mod--rounded-skin;
                  }
                  &.mod--highlighted-card {
                    filter: drop-shadow(4px 4px 24px rgba(0, 130, 155, 0.4));
                    .mod--highlighted-card__label {
                      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                      border-radius: 4px 0px 0px 4px;
                      left: initial;
                      right: 0px;
                      top: 20px;
                    }
                  }
                }
                &.mod--package {
                  .result__list-item {
                    width: ~"calc(33.3% - 16px)";
                    margin: 16px 12px;
                    padding: 0px;
                    &:first-child,
                    &:nth-child(3n + 4) {
                      margin-left: 0px;
                      width: ~"calc(33.3% - 16px)";
                    }
                    &:nth-child(3n + 3) {
                      margin-right: 0px;
                      width: ~"calc(33.3% - 16px)";
                    }
                  }
                }
              }
              &__comparator-bar {
                color: @grey-50;
                justify-content: flex-end;
                &.active {
                  background: var(--b-blue);
                  color: @white;
                }
              }
              &__map {
                padding-top: 34px;
              }
            }
          }
        }
      }
      &__sidebar {
        background: transparent;
        width: 25%;
        position: relative;
        left: inherit;
        top: inherit;
        overflow: visible;
        .z-layout;
        .sidebar {
          &__header {
            position: relative;
            top: inherit;
            left: inherit;
            height: 45px;
            background: transparent;
            box-shadow: none;
            padding: 0px;
            justify-content: flex-start;
            .sidebar__close-btn {
              display: none;
            }
            .sidebar__filter-btn {
              width: 28px;
              height: auto;
              display: inline-flex;
              margin-right: 10px;
            }
            .sidebar__title {
              font-size: 20px;
              line-height: 26px;
              font-weight: 700;
              color: @text-color;
            }
          }
          hr {
            display: block;
          }
          &__applicated-filters {
            display: block;
            .common-transition;
            .applicated-filters {
              &__filtered-item {
                background: @white;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                border-radius: 12px;
                height: 24px;
                display: inline-flex;
                padding: 3px 8px;
                margin: 0px 10px 10px 0px;
                color: var(--b-blue);
                .common-transition;
              }
              &__delete-filtered {
                color: var(--b-blue);
                margin-left: 10px;
                font-size: 12px;
                cursor: pointer;
                &:before {
                  font-family: "barcelo-ui-kit";
                  content: "\e909";
                }
              }
            }
          }
          &__facets-list {
            padding: 0px;
            .accordion-item {
              &.active {
                padding: 0px 0px 1rem 0px;
              }
              &:after {
                left: 0px;
              }
              &.active .accordion-content {
                padding-top: 1rem;
                .facet {
                  margin-bottom: 1rem;
                }
              }
            }
          }
          &__footer {
            position: relative;
            display: inline-block;
            bottom: inherit;
            left: inherit;
            background: transparent;
            box-shadow: none;
            padding: 0px;
            .mode-btn {
              display: block;
              &.map-btn,
              &.list-btn {
                .svg-icon {
                  margin-right: 0.4em;
                }
              }
            }
          }
        }
        .comparator-btn,
        .mode-btn {
          background: @grey-30;
          border-radius: @soft-corners;
          text-align: center;
          height: 40px;
          line-height: 40px;
          .style-anchor;
          margin: 16px 0px;
        }
      }
      &.active-comparator {
        .c-hotels-comparator__body {
          .comparator-btn-block {
            text-decoration: none;
          }
        }
      }
      .hotelSurprise3Position {
        margin: 10px 0px 10px 0px;
      }
    }
  }
}


// Fix wedding components 
.hotel-listing .bwd-weather .bwd-title .cmp-title__text,
.hotel-listing .bwd-map .bwd-title .cmp-title__text {
	font-family: Museo Sans;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.0015em;
    text-align: center;
}

.hotel-listing .bwd-map {
	.bwd-map__element.open {
		width: 100%;
	}
	.bwd-map__card-carousel,
	.bwd-map__modal {
		display: none;
	}
}