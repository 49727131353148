/* RTL STYLES - MOBILE FIRST*/
html[dir="rtl"] {
    body {
        direction: rtl;
    }
    a[href^="tel:"] {
        direction: ltr;
    }
    /* RTL FIXES - RIBBON - MOBILE FIRST (~ TABLET) */
    .c-ribbon {
        &__light {
            .cmp-text {
                padding: 5px 0px 5px 35px;
            }
        }
    }
    /* RTL FIXES - CMP-TITLE - MOBILE FIRST (~ TABLET) */
    .text-right {
        .cmp-title {
            text-align: left;
        }
    }
    .text-left {
        .cmp-title {
            text-align: right;
        }
    }
    /* RTL FIXES - CTA - MOBILE FIRST (~ TABLET) */
    .mod--cta-with-arrow-right .c-cta:after,
    .mod--cta-with-arrow-right.c-cta:after {
        transform: translateY(0.1em) rotate(-180deg);
    }
    .mod--cta-with-arrow-right .c-cta:hover:after,
    .mod--cta-with-arrow-right.c-cta:hover:after,
    .mod--cta-with-arrow-right .c-cta:active:after,
    .mod--cta-with-arrow-right.c-cta:active:after {
        transform: translateY(0.1em) translateX(-4px) rotate(-180deg);
    }
    .mod--cta-with-arrow-inline .c-cta:after {
        transform: translateX(0px) rotate(180deg);
    }
    .mod--cta-with-arrow-inline:hover .c-cta:after {
        transform: translateX(-4px) rotate(180deg);
    }
    .mod--cta-with-arrow-down .c-cta:after,
    .mod--cta-with-arrow-down.c-cta:after {
        transform: translateY(0.1em) translateX(-0.35rem) rotate(90deg);
    }
    .mod--cta-with-arrow-down .c-cta:hover:after,
    .mod--cta-with-arrow-down.c-cta:hover:after,
    .mod--cta-with-arrow-down .c-cta:active:after,
    .mod--cta-with-arrow-down.c-cta:active:after {
        transform: translateY(5px) translateX(-0.35rem) rotate(90deg);
    }

    /* RTL FIXES - HEADER & MEGAMENU - MOBILE FIRST*/
    .c-megamenu__menu-utils-item-anchor,
    .header .c-header__nav-container .c-aside-menu-user-item-anchor {
        flex-flow: row-reverse;
        justify-content: flex-end;
    }
    
    .header {
        .c-header__nav-container {
            .c-aside-menu {
                left: 0px;
                right: auto;
            }
            .c-aside-menu-user-item {
                &:first-child {
                    margin-left: 15px;
                }
                svg {
                    margin-right: 0;
                    margin-left: 8px;
                }
            }
            .c-aside-menu-user-item-anchor {
                .number-notification {
                    left: 60px;
                    right: initial;
                }
            }
        }
        .c-header__switch-logo {
            margin-right: 15px;
            margin-left: 0px;
        }
    }

    .c-megamenu__menu.c-position-wrapper {
        right: auto;
        left: 0px;
    }
    .c-megamenu__menu-utils-item:first-child,
    .c-aside-menu-user-item:last-child {
        margin-left: 30px;
    }
    .c-megamenu__menu-utils-item:last-child,
    .c-aside-menu-user-item:last-child {
        margin-left: 0px;
    }
    .c-megamenu__menu-utils-item-anchor.c-locate-nav__btn {
        margin-left: 16px;
    }
    .c-megamenu__menu-main-item .cmp-icon,
    .c-megamenu__menu-utils-item .cmp-icon {
        transform: rotate(180deg);
    }

    .c-megamenu__menu.c-position-wrapper .c-price__currency:before {
        display: none;
    }
    .c-megamenu__menu.c-position-wrapper .c-locate-nav .c-megamenu__menu-utils-item:before {
        left: -10px;
    }
    
    .c-megamenu {
        left: auto;
        right: -100vw;
    }
    .c-megamenu .c-popover {
        right: auto;
        left: -110vw;
    }
    .activeMegamenu .c-header .c-megamenu {
        left: auto;
        right: 0px;
    }
    .c-megamenu .c-popover.mod--active {
        left: 0px;
    }
    /* RTL - FIXES - MEGAMENU SUBMENU*/
    .c-submenu-container .c-submenu-btn__btn svg,
    .c-hotel-card__featured .c-hotel-card__header svg,
    .c-submenu-container .c-submenu-btn__btn .text .title .cmp-icon,
    .c-hotel-card__featured .c-hotel-card__header .svg-icon {
        transform: rotate(180deg);
    }

    .c-collection-list__list-item {
        margin-left: 30px;
        margin-right: 0px;
    }
    .c-submenu-container .cmp-title,
    .c-submenu-container .cmp-title__text {
        text-align: right;
    }
    .c-submenu-container .feed-entries .c-feed-entries--item .c-cta .c-launcher__imageside-header-title {
        margin-right: 70px;
        margin-left: 0px;
    }
    .c-megamenu__submenu-currency-select select,
    .c-megamenu__submenu-language .c-fieldset .mod--select-arrow {
        .mix-background-sprite(40px, 21, -5px);
        padding-left: 13%;
    }
    .c-megamenu__submenu-language .c-fieldset .mod--select-arrow .language-country {
        background-position: ~"calc(100% - 5px)" center;
        padding-right: 35px;
        padding-left: 0;
    }
    .c-megamenu__submenu-currency-select select {
        padding-right: 0.5rem;
    }
    .c-megamenu__submenu-language .c-fieldset .mod--select-arrow {
        padding-left: 0px;
    }
    .c-megamenu__submenu.smenu-lang .c-flag-select {
        right: 20px;
    }


    /* RTL FIXES - HEROSLIDER - MOBILE FIRST (~ TABLET) */

    .heroslider {
        .c-heroslider {
            &__central-block {
                &_cta {
                    .c-cta {
                        &:before {
                            margin-left: 9px;
                            margin-right: 0;
                        }
                    }
                }
                &_titles {
                    order: 1;
                }
            }
            &.swiper {
                .swiper-button {
                    &-prev {
                        &::after {
                            content: "\e901";
                        }
                    }
                    &-next {
                        &::after {
                            content: "\e902";
                        }
                    }
                }
            }
        }
    }

    /* RTL FIXES - SWIPER - MOBILE FIRST (~ TABLET) */
    .c-launcher__carousel-carousel .carousel .swiper-rtl .swiper-button-next,
    .c-launcher__carousel-carousel .carousel .swiper-button-next:after,
    .swiper-rtl .swiper-button-next {
        left: inherit;
        &::after {
            content: "\e902";
        }
    }
    .c-launcher__carousel-carousel .carousel .swiper-rtl .swiper-button-prev,
    .c-launcher__carousel-carousel .carousel .swiper-button-prev:after,
    .swiper-rtl .swiper-button-prev {
        right: inherit;
        left: 0;
        &::after {
            content: "\e901";
        }
    }

    /* RTL FIXES - CMP TABS SWIPER - MOBILE FIRST (~ TABLET) */
    .cmp-tabs .swiper-rtl .swiper-button-next {
        right: 10px;
        &::after {
            content: "\e902";
        }
    }
    .cmp-tabs .swiper-rtl .swiper-button-prev {
        right: inherit;
        left: 10px;
        &::after {
            content: "\e901";
            left: initial;
        }
    }

    /* RTL FIXES - CALENDAR - MOBILE FIRST (~ TABLET) */
    .datepicker__months .datepicker__month thead tr.datepicker__month-caption th .datepicker__month-button.datepicker__month-button--prev {
        transform: none;
    }
    .datepicker__months .datepicker__month thead tr.datepicker__month-caption th .datepicker__month-button.datepicker__month-button--next {
        transform: rotate(180deg);
        margin-left: -1px;
    }

    .datepicker .datepicker__months .datepicker__week-row .datepicker__month-day.datepicker__month-day--first-day-selected:before {
        border-width: 2px 2px 2px 0;
    }
    
    .datepicker .datepicker__months .datepicker__week-row .datepicker__month-day.datepicker__month-day--last-day-selected:before,
    .datepicker .datepicker__months .datepicker__week-row .datepicker__month-day.datepicker__month-day--hovering:hover:before {
        border-width: 2px 0 2px 2px;
    }

    .c-fastbooking__form .c-popover .c-popover__content.c-fastbooking__submenu-calendar .c-popover__content-block {
        border-left: 0;
    }

    .c-popover__content.c-fastbooking__submenu-discount .c-popover__content-block .form-submenu-discount .c-fieldset input[type=checkbox] {
        margin: 0 0 0 10px;
    }

    .c-adv {
        flex-direction: row-reverse;
        justify-content: center;
    }

    /* RTL FIXES - BUTTONS - MOBILE FIRST (~ TABLET) */
    .mod--cta-with-icon {
        .c-cta {
            flex-flow: row-reverse;
        }
    }
    .mod--cta-with-arrow-right:after {
        .c-cta {
            transform: rotate(-180deg);
        }
    }
    .c-back-bar__back-btn {
        flex-flow: row-reverse;
    }

    /* RTL FIXES - IMPUTS - MOBILE FIRST (~ TABLET)*/
    input[type="text"],
    input[type="tel"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    input[type="url"],
    input[type="date"],
    input[type="number"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    select {
        &.mod--select-arrow {
            .mix-background-sprite(45px, 21, -5px);
            padding-left: 13%;
        }
        &.mod--with-icon {
            padding: 0 8px 0 34px;
        }
    }

    /* RTL FIXES - FASTBOOKING - MOBILE FIRST (~ TABLET) */
    .inject-fastbooking-true .fastbooking .c-fastbooking__form .c-fieldset .mod--rooms .mod--select-arrow {
        .mix-background-sprite(40px, 21, -5px);
    }
    .c-fastbooking__links {
        &-add-flight {
            right: auto;
            left: 15px;
            text-align: left;
        }
    }
    .c-fastbooking__form {
        .c-fieldset {
            .mod--calendar .right-input input {
                border-left: 1px solid @grey-45;
            }
            .mod--rooms .mod--select-arrow {
                justify-content: end;
            }
        }

    }
    /* hidde flight cta for arabic site  */
    .flight-btn {
        &.invisible {
            display: none;
        }
    }
    /* RTL FIXES - FORMS - MOBILE FIRST (~ TABLET) */
    input[type="text"],
    input[type="tel"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    input[type="url"],
    input[type="date"],
    input[type="number"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    select {
        &.mod--select-arrow {
            padding-left: 13%;
            .mix-background-sprite(45px, 21, 0);
        }
    }

    .c-double-input {
        .left-input {
            margin-left: -1px;
            input {
                border-radius: 0px @soft-corners @soft-corners 0px;
            }
        }
        .right-input {
            margin-left: 0px;
            input {
                margin-right: 0px;
                border-radius: @soft-corners 0px 0px @soft-corners;
            }
        }
    }
    /*  RTL FIXES - TYPO - MOBILE FIRST (~ TABLET) */
    .cmp-text,
    .c-launcher,
    .launcher,
    .c-card,
    .c-card-hotel-list,
    .c-card-hotel__content-body-list {
        ul {
            li {
                padding-left: inherit;
                padding-right: 1em;
                &:before {
                    left: inherit;
                    right: 0px;
                }
            }
            &.mod--checks {
                li {
                    padding-left: inherit;
                    padding-right: 1.5em;
                }
            }
        }
        ol {
            li {
                padding-left: inherit;
                padding-right: 2em;
                &:before {
                    right: 0px;
                }
            }
        }
        .mod--checks {
            .c-card-hotel__content-body-list-item {
                padding-left: inherit;
                padding-right: 1.5em;
                &:before {
                    left: initial;
                    right: 0;
                }
            }
        }
    }

    /* RTL FIXES - TEXT-IMAGE - MOBILE FIRST (~ TABLET) */
    .textimage {
        &.mod--image-left {
            .c-textimage {
                .icon {
                    margin: 0px 0px 0px 0.9rem;
                }
                .cmp-text {
                    text-align: right;
                }
            }
        }
        &.mod--inline {
            .c-textimage {
                .icon,
                .image {
                    margin-left: 0.75rem;
                    margin-right: 0;
                }
            }
        }
    }
    /* RTL FIXES - PROGRESS BAR - MOBILE FIRST (~ TABLET) */
    .c-progress-bar {
        &__milestone,
        &__goal {
            &-label {
                transform: translateX(50%);
            }
            &-dot {
                left: auto;
                right: -9px;
            }
            &-bar {
                &:before {
                    right: -100%;
                    left: auto;
                }
            }
            &.mod--active {
                .c-progress-bar {
                    &__milestone,
                    &__goal {
                        &-bar {
                            &:before {
                                left: auto;
                                right: 0%;
                            }
                        }
                    }
                }
            }
            &.mod--last-active {
                .c-progress-bar {
                    &__milestone,
                    &__goal {
                        &-bar {
                            &:before {
                                left: auto;
                                right: -50%;
                            }
                        }
                    }
                }
            }
        }
    }
    /* RTL FIXES - ACORDION - MOBILE FIRST (~ TABLET) */
    .accordion-toggle {
        padding-left: 20px;
        &.mod--icon-right {
            flex-flow: row;
            .svg-icon.accordion-arrow {
                transform: rotate(180deg);
            }
        }
        .svg-icon.accordion-arrow {
            transform: rotate(180deg);
            margin-right: 0;
            position: absolute;
            left: 0;
        }
    }
    .accordion-item.active {
        .accordion-toggle {
            .svg-icon.accordion-arrow {
                transform: rotate(90deg);
            }
        }
    }
    .c-gallery-summary__content {
        right: 15px;
        &-icon {
            margin-right: 0;
            margin-left: 6px;
        }
    }
    /* RTL FIXES - ACORDION DESTINATION LIST - MOBILE FIRST (~ TABLET) */
    .destinations-list {
        &.accordion {
            .accordion-item {
                .accordion-toggle {
                    .svg-icon {
                        &.accordion-arrow {
                            .svg-icon {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
    /* RTL FIXES - LAUNCHER - MOBILE FIRST (~ TABLET) */
    .c-launcher__imageside .c-container-image {
        margin-right: 0px;
        margin-left: 15px;
    }
    .mod--image-reverse-m .c-container-image {
        margin-left: 0px;
        margin-right: 15px;
    }
    .c-launcher__carousel .carousel {
        // margin-right: -15px;
        margin-right: 0px;
    }
    .swiperAem {
        .swiper-ui {
            &__area {
                &-prev {
                    left: inherit;
                    right: 0;
                    transform: translateX(90%);
                }
                &-next {
                    right: inherit;
                    left: 0;
                    transform: translateX(-90%);
                }
            }
        }
    }
    .launcher {
        &.mod--image-reverse-d {
            .c-launcher__carousel {
                &-carousel {
                    // margin-left: 6%;
                    // margin-right: 15px;
                }
            }
        }
        &:not(.mod--image-reverse-d) {
            .c-launcher__carousel {
                flex-direction: row-reverse;
            }
        }
    }

    /* RLT - SUMMARY GALLERY - MOBILE FIRST (~ TABLET) */
    .c-gallery-summary {
        margin-right: -15px;
    }

    /* RTL FIXES - WEATHER - MOBILE FIRST (~ TABLET) */
    .hotel-weather .c-weather__body-section {
        &:first-child {
            border-left: 1px solid #d8d8d8;
            border-right: 0px;
            padding-left: 15px;
            padding-right: inherit;
        }
        &:last-child {
            padding-right: 15px;
            padding-left: inherit;
        }
    }
    /* RTL FIXES - HEADER-DETAIL - MOBILE FIRST (~ TABLET) */
    .c-header-detail {
        &__nav-wrapper,
        &__nav-left,
        &__nav-right {
            flex-flow: row-reverse;
        }
        .c-hotel-detail {
            &__card {
                &__list {
                    .list__item {
                        .c-icon {
                            margin: 0px 0px 0px 10px;
                        }
                    }
                }
            }
        }
    }

    /* RTL FIXES - HOTEL-DETAIL - MOBILE FIRST (~ TABLET) */
    .c-hotel-detail{
        &__text {
            &.c-rich-text {
                .title.generic-title.text-left {
                    .cmp-title__text {
                        text-align: right;
                    }
                }
            }
        }
    }
    /* RTL FIXES - TINGLE MODAL */
    .tingle-modal-box__content {
        &.headline-white {
            .c-modal__header .cmp-title {
                text-align: left;
                padding-right: inherit;
                padding-left: 16px;
            }
            .tingle-modal__close {
                left: inherit;
                right: 16px;
            }
        }
    }
    /* RTL FIXES - CARDS HOTEL -  */
    .c-card-hotel {
        &__content {
            &-header {
                text-align: right;
                &-title {
                    padding-right: 0px;
                    padding-left: 1rem;
                }
                &-location-link {
                    .svg-icon {
                        margin-right: inherit;
                        margin-left: 0.15em;
                    }
                }
            }
            &-body {
                &-info {
                    .c-tripadvisor {
                        .svg-icon {
                            margin-right: inherit;
                            margin-left: 0.35em;
                        }
                    }
                    &-label {
                        text-align: left;
                    }
                }
            }
            &-footer {
                .c-offer {
                    .c-card-hotel__content-footer-discount-claim,
                    .c-card-hotel__content-footer-price-claim {
                        margin-right: 0;
                        margin-left: 0.5em;
                    } 
                }
               
            }
        }
        &.c-card-hotel-offer {
            .c-card-coupon__promo {
                text-align: right;
            }
        }
    }
    .result__map {
        .c-card-hotel {
            &__content-header,
            &__content-body {
                padding: 23px 150px 0px 15px;
            }
            &__content-header {
                text-align: right;
            }
            &__content-footer {
                .mod--cta-full-width {
                    text-align: left;
                }
            }
        }
    }
    /* RTL FIXES - TRIPADVISOR - MOBILE FIRST (~ TABLET) */
    .c-tripadvisor {
        .ta__dot {
            margin-right: 0px;
            margin-left: 1px;
            &--half,
            &--full {
                margin-right: 0px;
                margin-left: 1px;
            }
            &--half {
                transform: rotate(180deg);
            }
        }
        &-details__content-user {
            &-header {
                .ta-profile {
                    .ta-avatar {
                        margin-left: 8px;
                        margin-right: 0;
                    }
                }
            }
            &-message {
                &:before {
                    left: initial;
                    right: 2px;
                }
            }
        }
    }
    /* RTL FIXES - COMPARATOR -  */
    .hotels-comparator,
    .offers-comparator,
    .meetings-comparator {
        .c-hotels-comparator {
            &__body {
                .body__main-nav {
                    .main-nav__hotels-btn,
                    .main-nav__select {
                        border-right: none;
                        border-left: 1px solid #b1b1b1;
                    }
                }
                .body__result {
                    .result__comparator-bar {
                        padding-left: 16px;
                        &:after {
                            margin-right: 8px;
                            margin-left: 0px;
                        }
                    }
                    .result__list {
                        &.mod--compact-meeting {
                            .result__list-item {
                                .c-card-hotel__content {
                                    &-body-list {
                                        .mod--checks {
                                            .c-card-hotel__content-body-list-item {
                                                padding-right: 20px;
                                                &::before {
                                                    left: initial;
                                                    right: 0;
                                                }
                                            }
                                        }
                                    }
                                    &-header {
                                        &-location-link {
                                            padding-right: 20px;
                                        }
                                        &-title {
                                            padding-right: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__sidebar {
                .sidebar__facets-list {
                    .accordion-item {
                        .accordion-content {
                            .facet {
                                label {
                                    padding-right: 0; // Remove when new facets are available
                                }
                            }
                        }
                    }
                    [data-dimension=price] {
                        &.accordion-item {
							.accordion-content {
                                &.filter-price {
                                    .fake-facet-input {
                                        left: -4px;
                                        label {
                                            &:after {
                                                left: initial;
                                                right: -7px;
                                            }
                                        }
                                    }
                                    .filter-price--amount-slider {
                                        input {
                                            &:checked {
                                                &~label:before {
                                                    right: initial;
                                                    left: 4px;
                                                }
                                            }
                                        }
                                        label:before,
                                        label:hover:before {
                                            right: initial;
                                            left: 4px;
                                        }
                                        .facet {
											&:first-of-type label::before,
											&:first-of-type label:hover::before {
												content: "";
											}
											
										}
                                    }
                                }
                            }
                        }
                    }
                }
                .sidebar__header{
                    .sidebar__title {
                        padding-right: 10px;
                    }
                }
                .sidebar__footer {
                    .mode-btn {
                        &.map-btn,
                        &.list-btn {
                            .svg-icon {
                                margin-right: 0;
                                margin-left: 0.4em;
                            }
                        }
                    }
                }
            }
        }
    }
    /* RTL FIXES - TAILWIND */
    .text-left {
        text-align: right;
    }
    .text-right {
        text-align: left;
    }
    /* RTL FIXES - HORIZONTAL CARD COMPACT - MOBILE FIRST (~ TABLET) */
    .mod--horizontal-card-compact {
        .c-card-hotel-my-barcelo-price {
            &.popup-bubble {
                .c-card-hotel {
                    &__image {
                        height: calc(100% - 115px);
                    }
                }
            }
        }
        .c-card-hotel {
            &__content {
                &-header,
                &-body {
                    padding: 23px 42% 0 15px;
                }
                &-footer {
                    .mod--cta-full-width {
                        text-align: left;
                    }
                }
            }
            .c-close-btn {
                left: 5px;
                right: initial;
            }
        }
    }
    /* RTL FIXES - MYB ICON - HOTEL ROOM LIST - MOBILE FIRST (~ TABLET) */
    .hotel-room-list {
        .c-hotel-room-list {
            &__body {
                &-group {
                    padding-right: 0!important;
                }
                &-persons {
                    .sprite-icon-people {
                        margin-left: 6px;
                    }
                }
                &-list {
                    .sprite-icon-adult {
                        margin-left: 2px;
                    }
                    &-ul {
                        padding-right: 18px;
                        .svg-icon {
                            right: 0;
                        }
                    }
                }
                &-people {
                    padding: 10px 0px 0 0;
                    .c-hotel-room-list__body-persons {
                        padding-left: 14px;
                        padding-right: 0;
                        &::after {
                            left: 6px;
                            right: initial;
                        }

                        .sprite-icon-people {
                            margin-left: 5px;
                        }
                    }
                }
                &-link {
                    text-align: left;
                }
                &-list {
                    margin-left: 0;
                    &-ul {
                        li {
                            padding: 8px 18px 0 0;
                            min-width: 100px;
                        }
                    }
                }
            }
            &__footer {
                .myb-icon {
                    left: 62%;
                    margin-right: -26px;
                    margin-left: 0;
                }
                .c-cta-container {
                    margin-left: inherit;
                    margin-right: auto;
                }
                &__booking-info {
                    .c-price__type {
                        margin-right: 0.25rem;
                    }
                }
            }
            &.c-hotel-room-my-barcelo-price {
                .c-hotel-room-list__footer-price {
                    &.c-price-normal-myb {
                        width: auto;
                    }
                }
            }
        }
    }
    /* RTL FIXES - BOX BANNER LOGIN MYB IN HOTEL ROOM LIST - MOBILE FIRST (~ TABLET) */
    .hotel-room-list {
        .c-hotel-room-list {
            &.mod--login-myb-box-shown {
                .c-hotel-room-list__content-wrapper {
                    .c-hotel-room-list__header {
                        margin: 8px 8px 8px 0;
                    }
                }
            }
        }
    }
    /* RTL FIXES - HOTEL PAGE MAP - MOBILE FIRST (~ TABLET) */
    a[href^="tel:"].c-hotel-map__content,
    .c-hotel-map__content {
        &-links-item {
            direction: rtl;
        }
        .icon {
            margin: 0 0 0 10px;
            margin-right: 0!important;
        }
    }
    /* RTL FIXES - CURRENCY ICON PRICES - MOBILE FIRST (~ TABLET) */
    .c-price__currency {
        margin-right: 0.25rem;
        order: 0;
    }

    /* RTL FIXES - MYB PRICES - MOBILE FIRST (~ TABLET) */
    .c-price.c-price-myb {
        &>.c-card-hotel__content-footer-price-claim {
            padding: 2px 12px 0 0;
            &:after {
                right: initial;
                left: -22px;
            }
        }
        .c-price-myb__container {
            .c-price {
                justify-content: flex-start;
            }
        }
    }
    .mod--myb-switch--active {
        .c-price.c-price-myb .c-price-header .c-card-hotel__content-footer-price-claim {
            margin-right: 0;
            &:after {
                right: initial;
                left: -22px;
            }
        }
    }
    .c-price.c-price-normal-myb {
        padding-left: 2px;
        padding-right: 0;
        .c-card-hotel__content-footer-price-claim {
            margin-right: 2px;
        }
    }
    /* RTL FIXES - MYB TRIGGER BANNER - MOBILE FIRST (~ TABLET) */
    .myb-trigger-banner {
        .c-myb-trigger-banner__content {
            .c-myb-trigger-banner__features {
                .c-myb-trigger-banner__feature {
                    &.mod--myb-feature__card {
                        .c-myb-trigger-banner__feature-icon {
                            margin-right: 0;
                            margin-left: 10px;
                        }
                    }
                }
            }
            &-right {
                .c-myb-trigger-banner__switcher-text {
                    &>:before,
                    &:before {
                        left: -10px;
                    }
                }
            }
        }
    }
    .c-myb-push-banner .c-ribbon__push-banner .icon {
        margin-left: 22px;
        margin-right: 0;
    }

    .c-myb-push-banner:not(.hidden) {
        & ~ .c-head-hotel-info {
            .c-head-hotel-info__booking-btn {
                .c-cta {
                    min-width: 179px;
                }
            }
        }
    }
    /* RTL FIXES - MYB BANNER - DESKTOP */
    .c-myb-banner {
        &__item {
            &__header {
                &__feature__item {
                    &-icon {
                        margin-right: 0;
                        margin-left: 16px;
                    }
                }
            }
            &__footer {
                padding: 48px 64px;
                &__content__login {
                    border-left: 0;
                    padding-left: 0;
                    border-right: 1px solid #afb3b4;
                    padding-right: 24px;
                }
            }
            &__legend::before {
                left: 140px;
            }
        }
    }
    /* RTL FIXES - HEAD HOTEL - MOBILE FIRST (~ TABLET) */
    .head-hotel-info {
        .c-head-hotel-info {
            &.mod--stick-bottom {
                .c-head-hotel-info__booking-btn {
                    margin: 0 6px;
                }
                .c-head-hotel-info__booking-info-price {
                    .c-price-from {
                        left: initial;
                        top: -5px;
                    }
                }
            }
            &__booking {
                &-info {
                    margin-right: 0;
                    margin-left: 60px;
                    &-claim {
                        text-align: right;
                    }
                    &-price {
                        .c-price {
                            &-from {
                                left: initial;
                                top: 5px;
                            }
                            &__currency {
                                margin-right: 0;
                            }
                            &__curre-type-wrapper {
                                margin-right: 0.25rem;
                            }
                        }
                    }
    
                    .c-price.c-price-myb {
                        .c-card-hotel__content-footer-price-claim {
                            &.required-right {
                                &:before {
                                    right: initial;
                                    left: -30px;
                                }
                            }
                        }
                    }
                }
                &-btn {
                    .c-cta {
                        min-width: 140px;
                    }
                }
            }
            &__info {
                &-title {
                    text-align: right;
                }
                &-resources {
                    margin-left: 15px;
                    margin-right: 0;
                    &-map {
                        margin-right: 0;
                        margin-left: 26px;
                    }
                }
                .c-tripadvisor {
                    .icon-tripadvisor {
                        margin-right: 0;
                        margin-left: 8px;
                    }
                    .ta__social {
                        margin-left: 0;
                        margin-right: 8px;
                        &::after {
                            right: -9px;
                            left: initial;
                        }
                    }
                }
                &-tag {
                    margin-right: 0;
                }
            }
            /* RTL FIXES - CURRENCY ICON PRICES - MOBILE FIRST (~ TABLET) */
            .c-price__currency {
                order: 0;
            }
        }
    }

    /*  PROMO LIST  */
    .c-promo-list {
        &__card {
            .mod--seo-layout.c-card  {
                .c-card-hotel__content-footer {
                    .c-label {
                        flex-direction: row-reverse;
                    }
                }
            }
        }
    }
    /* RTL FIXES - FOOTER - MOBILE FIRST (~ TABLET) */
    .xpf_reference_footer {
        .generic-container {
            .generic-container {
                &.aem-GridColumn.aem-GridColumn--default--none,
                .v-menu {
                    width: 100%;
                }
            }
        }
        .footer {
            padding-top: 1px;
        }
        .c-refooter__social {
            margin-right: auto;
            margin-left: 0;
        }
    }
}

/* RTL - DESKTOP */
@media (min-width: @desktop) {
    html[dir="rtl"] {

        /* RTL FIXES - HEADER & MEGAMENU - DESKTOP */
        .header {
            .c-header__nav-container {
                .c-aside-menu-user-item {
                    margin-left: 0;
                    margin-right: 20px;
                }
                .c-aside-menu-user-item:first-child {
                    margin-left: 0px;
                }
                .c-aside-menu-user-item-anchor {
                    .number-notification {
                        top: 10px;
                        left: -10px;
                        right: initial;
                    }
                }
            }
            .c-header__switch-logo {
                margin-right: 0;
            }
        }
        .c-megamenu {
            left: auto;
            right: auto;
        }
        .c-megamenu .c-popover {
            left: auto;
        }
        .c-gallery-summary__content {
            right: 30px;
            &-icon {
                margin-left: 8px;
            }
        }

        /* RTL FIXES - FASTBOOKING - DESKTOP */
        .inject-fastbooking-true .c-fastbooking__links {
            &-add-flight {
                right: 0px;
                left: auto;
                text-align: right;
            }
        }
        .c-fastbooking__form {
            .c-popover {
                left: auto;
                .c-popover__content-block {
                    border-right: none;
                    border-left: 2px solid #e0e0e0;
                    .c-fastbooking__submenu-header .svg-icon {
                        margin-left: 0.25rem;
                        margin-right: 0;
                    }
                }
                .c-fastbooking__submenu-destination, .c-fastbooking__submenu-search {
                    width: 100vw;
                }
                .c-fastbooking__submenu-destination-history {
                    .mod--accordion-disabled-mobile {
                        .accordion-content {
                            &>.accordion-item {
                                &::before {
                                    right: 10px;
                                    transform: rotate(180deg);
                                }
                                .accordion-label {
                                    padding-left: 0;
                                    padding-right: 26px;
                                }
                            }
                        }
                        &>li ul li ul li {
                            padding-left: 0px;
                            padding-right: 42px;
                        }
                    }
                }
                .c-fastbooking__submenu-rooms.c-popover__content {
                    .c-popover__content-block {
                        .ribbon {
                            .c-ribbon__light {
                                padding: 8px 10px 8px 28px;
                            }
                            .c-ribbon__close-btn{
                                left: 7px;
                                right: initial;
                            }
                            .c-ribbon__icon {
                                padding-left: 6px;
                                padding-right: 0;
                                border-left: 1px solid #fff;
                                border-right: none;
                            }
                        }
                    }
                }
            }
            .c-fieldset {
                .mod--destination,
                .mod--calendar {
                    .c-delete-dates {
                        &:after {
                            right: 6px;
                        }
                    }
                }
            }
        }

        .inject-fastbooking-true .fastbooking .c-fastbooking__form .c-fieldset .mod--rooms .mod--select-arrow {
            .mix-background-sprite(45px, 21, -5px);
        }

        /* RTL FIXES - SWIPER - MOBILE FIRST (~ TABLET) */
        .c-launcher__carousel-carousel .carousel .swiper-rtl .swiper-button-prev,
        .swiper-rtl .swiper-button-prev {
            // left: ~"calc((100% - 1140px) / 2)";
            left: 0;
        }
        .c-launcher__carousel-carousel .carousel .swiper-rtl .swiper-button-prev {
            &::after {
                left: 4px;
            }
        }
            /* IF ITS NEW LARGE WIDTH */
        .c-container__grid-wrapper.--new-max-width {
            .c-launcher__carousel-carousel .carousel .swiper-rtl .swiper-button-prev,
            .c-launcher__carousel-carousel .carousel .swiper-button-prev:after,
            .swiper-rtl .swiper-button-prev {
                left: 0;
            }
        }
        /* RTL FIXES - HEROSLIDER - DESKTOP */
        .heroslider .c-heroslider.swiper.swiper-rtl .swiper-button-prev {
            left: ~"calc((100% - 1140px) / 2)";
        }
        /* RTL FIXES - FOOTER - DESKTOP*/
        .c-refooter__logo {
            margin-left: 30px;
        }
        /* RTL FIXES - WEATHER - DESKTOP */
        .hotel-weather .c-weather__body-section {
            &:first-child {
                padding-left: 30px;
            }
            &:last-child {
                padding-right: 30px;
            }
        }
        /* RTL FIXES - HEADER-DETAIL - DESKTOP */
        .c-header-detail {
            .c-hotel-detail {
                .c-side-content:first-child {
                    padding-left: 30px;
                    padding-right: inherit;
                }
                &__card {
                    &__list {
                        .list__item {
                            .c-icon {
                                margin-left: 15px;
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }
        }
            /* RTL FIXES - HEAD HOTEL - MOBILE FIRST (~ TABLET) */
        .head-hotel-info {
            .c-head-hotel-info {
                &__booking-info {
                    .c-price.c-price-myb {
                        .c-card-hotel__content-footer-price-claim {
                            &.required-right {
                                &:before {
                                    left: -8px;
                                }
                            }
                        }
                    }
                }
            }
        }
        /* RTL FIXES - COMPARATOR - DESKTOP */
        .hotels-comparator,
        .offers-comparator,
        .meetings-comparator {
            .c-hotels-comparator__body {
                margin-right: 32px;
                margin-left: inherit;
                .body__main-nav {
                    .main-nav__hotels-btn,
                    .main-nav__select {
                        border-left: none;
                    }
                    .mode-btn {
                        .map-btn-block,
                        .list-btn-block {
                            .svg-icon {
                                margin-left: 0.5em;
                                margin-right: inherit;
                            }
                        }
                        .list-btn-block {
                            .svg-icon {
                                transform: scaleX(-1);
                            }
                        }
                    }
                    .main-nav__select label {
                        padding: 0 0 0 10px;
                    }
                }
            }
        }

        /* RTL FIXES - COMPARATOR OFFERS - DESKTOP */
        .c-card-hotel-offer {
            &.c-card-hotel {
                .c-card-coupon__promo {
                    .c-card-hotel__content-footer-location-link {
                        .svg-icon {
                            margin-right: 0;
                            margin-left: 0.15em;
                        }
                    }
                }
            }
        }  

        /* RTL FIXES - MYB ICON - HOTEL ROOM LIST - DESKTOP */
        .hotel-room-list {
            .c-hotel-room-list {
                &__body {
                    &-main {
                        justify-content: space-between;
                    }

                }
                &__footer {
                    .myb-icon {
                        left: 10px;
                        display: flex;
                        right: initial;
                    }
                }
            }
        }
        /* RTL FIXES - MYB PRICES - DESKTOP */
        .c-price.c-price-myb {
            &>.c-card-hotel__content-footer-price-claim__dstp {
                padding: 7px 14px 0 0;
            }
            .c-price-header {
                .bhg-tooltip {
                    right: initial;
                    left: 8px;
                    left: 12px;
                }
            }
        }

        .mod--myb-switch--active {
            .c-card-hotel.c-card-hotel-my-barcelo-price {
                .c-card-hotel__content-footer.mod--hotel-my-barcelo {
                    .c-card-hotel__content-footer-price {
                        .c-price-myb__container {
                            padding: 5px 14px;
                        }
                    }
                }
                &.popup-bubble{
                    .c-card-hotel__content-footer.mod--hotel-my-barcelo {
                        .c-card-hotel__content-footer-price {
                            .c-price-myb {
                                margin-bottom: 8px;
                                transition: none;
                            }
                            .c-price-myb__container {
                                .c-price-header {
                                    .bhg-tooltip {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .bhg-tooltip--icon {
                        &::after {
                            content: none;
                        }
                    }
                }
            }
            .head-hotel-info {
                .c-head-hotel-info__booking-info {
                    .c-price-normal-myb {
                        margin: 4px 0 0 8px;
                    }
                    .c-price {
                        &.c-price-myb {
                            .c-price-myb__container {
                                padding: 5px 14px 5px 14px;
                                .c-price-header {
                                    .bhg-tooltip {
                                        right: auto;
                                        left: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .c-price-myb__container {
                .c-price-header {
                    .c-card-hotel__content-footer-price-claim {
                        &.c-card-hotel__content-footer-price-claim__dstp {
                            padding-left: 18px;
                            .required-right {
                                &:before {
                                    left: -10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        /* RTL FIXES - MYB TRIGGER BANNER - DESKTOP */
        .myb-trigger-banner {
            &:not(.mod--compact-carousel) {
                .c-myb-trigger-banner__content {
                    .c-myb-trigger-banner__features {
                        .c-myb-trigger-banner__feature {
                            &.mod--myb-feature__card {
                                .c-myb-trigger-banner__feature-icon {
                                    width: 100%;
                                    display: flex;
                                    justify-content: flex-end;
                                    padding-left: 14px;
                                }
                            }

                            &.mod--myb-feature__plain {
                                .c-myb-trigger-banner__feature-icon {
                                    margin-right: 0;
                                    margin-left: 12px;
                                }
                            }
                        }
                    }

                    .c-myb-trigger-banner__accordion {
                        .accordion-label {
                            margin-right: 0;
                            margin-left: 10px;
                        }
                        .accordion-toggle {
                            &.mod--centered {
                                flex-flow: row-reverse;
                            }
                            &.mod--icon-right {
                                .svg-icon {
                                    &.accordion-arrow {
                                        position: relative;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        /* RTL FIXES - MYB NEW MYB PAGE SWIPPER - DESKTOP */
        .c-myb-hero__background-image {
            right: initial;
            left: 0;
        }
        .c-myb-hero__background-info__title,
        .c-myb-hero__background-info__description p,
        .myb-embed-title.title.c-myb-details__header-title .cmp-title__text {
            text-align: right;
        }

        /* RTL FIXES - HEADER CONTACT PHONE NUMBER - DESKTOP */
        .c-megamenu__menu-call-btn {
            right: initial;
            position: relative;
            padding-left: 20px;
            &:after {
                left: 0;
            }
            .cmp-icon {
                left: initial;
                right: 0;
            }
            &>span {
                margin-right: 6px;
            }
        }
        .c-megamenu__menu-utils-item:first-child:after {
            content: "";
            border-left: 1px solid #fff;
            position: absolute;
            right: 0;
            height: 10px;
            top: 50%;
            transform: translateY(-50%);
            left: 50%;
        }

        /* RTL FIXES - MYB NEW MYB PAGE MYB-FEATURES-2 - DESKTOP */
        .c-myb-features-2.mod--essential-hover .c-myb-features-2__wrapper::before {
            right: calc(29% + 40px);
        }
        .c-myb-features-2.mod--special-hover .c-myb-features-2__wrapper::before {
            right: calc(53.8% + 20px);
        }
        .c-myb-features-2.mod--unique-hover .c-myb-features-2__wrapper::before {
            right: initial;
            left: 0;
        }

        .c-myb-features-2 .accordion-item {
            .accordion-icon {
                margin-right: 0;
                margin-left: 8px;
            }
        }
        .c-myb-features-2__wrapper .accordion-item>.accordion-toggle .svg-icon.accordion-arrow {
            margin-left: 20px;
        }

        /* RTL FIXES - MYB NEW LATERAL MENU - DESKTOP */
        .user-item--login.mod--barceloMenuV2 {
            .c-myb-menu.mod--menu-v2 .c-myb-menu--login__itemMyb-icon {
                margin-right: initial;
                margin-left: 10px;
            }
            .c-myb-menu .c-myb-logo--heading-text {
                padding-left: initial;
                padding-right: 24px;
            }
            .c-myb-menu.mod--menu-v2 .c-myb-logo {
                margin-right: 0;
            }
            .c-myb-menu.mod--menu-v2 .c-myb-menu__nav-item {
                margin-left: auto;
            }
        }
        .header .c-header__nav-container .c-aside-menu .c-popover.user-item--login.mod--barceloMenuV2 {
            left: -385px;
            right: initial;
        }
        .header .c-header__nav-container .c-aside-menu .c-popover.user-item--login.mod--barceloMenuV2.mod--active {
            left: 0 !important;
            right: initial;
        }


        /* RTL FIXES - MYB NEW MYB PAGE MYB TABS MENU - DESKTOP */
        .c-tab-menu__nav {
            .c-tab-menu__nav-item {
                .cmp-icon {
                    margin-left: 9px;
                    margin-right: initial;
                }
            }
        }


        /* RTL FIXES - MYB NEW MYB PAGE MYB PREFERENCIES TAB - DESKTOP */
        .c-myb-preferences {
            &__language {
                .c-myb-language__content .iti__flag {
                    right: 50px;
                    position: absolute;
                    z-index: 1;
                }
                .c-myb-language__select {
                    padding-left: initial;
                    padding-right: 86px;
                }
            }
            &__currency .c-myb-currency__select {
                padding-right: 48px;
            }

            &__items-header__title,
            &__communication-header__title,
            .c-myb-language__header-title,
            .c-myb-currency__header-title {
                .cmp-title__text {
                    text-align: right;
                }
            }
            &__items-header__description p,
            &__communication-header__description p,
            .c-myb-language__header-description p,
            .c-myb-currency__header-description p {
                text-align: right;
            }

            &__communication-form__check {
                label::before {
                    margin-right: initial;
                    margin-left: 12px;
                }
                .cmp-text p {
                    text-align: right;
                }

            }
        }
        
        /* RTL FIXES - MYB NEW MYB PAGE MYB TABS MENU - DESKTOP */
        .c-tab-menu__nav {
            .c-tab-menu__nav-item {
                .cmp-icon {
                    margin-left: 9px;
                    margin-right: initial;
                }
            }
        }


        .myb-loyalty-hero {
            .c-l-hero__main__text {
                padding-right: 64px;
            }
        }
        .c-l-hero__main__cards-1 {
            margin-right: initial;
            margin-left: 24px;
            justify-content: center;
        }

        .c-l-info__main__levels {
            .level-icon {
                margin-left: initial;
                margin-right: 8px;
            }
        }






        /* RTL FIXES - MYB NEW MYB PAGE MYB-FEATURES-2 FINAL ACCORDION  - DESKTOP */
        #home_mybarcelo {
            .generic-container {
                .cmp-title__text {
                    text-align: right;
                }
                .accordion-item.mod--arrow-on-right .accordion-toggle .accordion-toggle__title-standard {
                    width: 100%;
                    text-align: right;
                }
            }
        }
        .generic-title .cmp-title__text {
            text-align: right;
        }

        .accordion-item.mod--arrow-on-right .accordion-toggle {
            text-align: right;
            justify-content: flex-end;
        }


        .c-campaign-list__col {
            .c-card__image {
                .c-label::before {
                    margin-right: initial;
                    margin-left: 8px;
                }
            }
        }

        .c-myb-booking-history__list__item-reference {
            margin-right: inherit;
            margin-left: 8px;
        }

        .c-fieldset__row-wrap-col.mod--country {
            .c-myb-edit-profile-v2__personal-container .c-fieldset__row-wrap-col.mod--country .iti__flag {
                left: initial;
                right: 8px;
            }

        }




        /* RTL FIXES - FOOTER - DESKTOP */
        .xpf_reference_footer {
            .generic-container {
                .generic-container {
                    &.aem-GridColumn.aem-GridColumn--default--none,
                    .v-menu {
                        width: initial;
                    }
                }
            }
        }

        /* RTL FIXES - LAUNCHER - DESKTOP */
        .launcher {
            &.mod--image-reverse-d {
                .c-launcher__carousel {
                    flex-direction: row;
                        &-carousel {
                            margin-left: 6%;
                            margin-right: 15px;
                        }
                        .carousel {
                            margin-right: -15px;
                        }
                    }
                }
            .c-launcher {
                &__image {
                    .c-launcher__overhead {
                        .mod--cta-fullbg {
                            padding: 0 23px;
                        }
                    }
                }
                &__carousel {
                    .carousel {
                        // margin-right: -15px;
                    }
                }
            }
        }

        /* C-highlights-SWIPER - DESKTOP  */
        .c-highlights-swiper{
            &-desktop {
                .c-highlights-swiper__tab-container{
                    .c-highlights-wrapper {
                        .c-highlights-slide {
                            &:first-of-type {
                                right: 0;
                            }
                        }
                    }
                }
            }
            .c-modal-link {
                left: 0;
                right: initial;
            }
        }

        /* RTL FIXES - HEROSLIDER - DESKTOP */
        .heroslider {
            .c-heroslider {
                &.mod--layout-urban {
                    .c-heroslider__urban-gallery{
                        &-items {
                            .swiper-slide {
                                &:first-child {
                                    right: initial;
                                    left: 0px;
                                }
                            }
                        }
                    }
                }
                .inject-breadcrumb {
                    &.c-container-collapsed {
                        right: initial;
                    }
                }
            }
        }
    }
}

/* RTL - MOBILE ONLY */
@media (min-width: @mobile) and (max-width: @desktop) {
    .mod--align-left-mobile {
        .cmp-title {
            text-align: right;
        }
    }
    .mod--align-right-mobile {
        .cmp-title {
            text-align: left;
        }
    }
}
