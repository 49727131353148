//	 __          __              _             _
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |
//	                                     |___/
//
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

@import (once) "./src/main/webpack/clientlibs/base/less/inc/utils/variables";
@import (once) "./src/main/webpack/clientlibs/base/less/inc/utils/animations";

/* UTILS */
.content-sizzing {
  width: 100%;
  max-width: @content-sizing;
  margin: auto;
}

.break-container {
  width: ~"calc(100% + 30px)" !important;
  /* NECESARIO PARA ROMPER EL CONTAINER */
  margin-left: -15px;
}

.default-container {
  width: 100% !important;
  /* NECESARIO PARA VOLVER EL CONTAINER A SU ESTADO ORIGINAL */
  margin-left: inherit;
}

.break-container-mobile {
  width: ~"calc(100% + 30px)" !important;
  /* NECESARIO PARA ROMPER EL CONTAINER */
  margin-left: -15px;
}

.common-focus {
  border-radius: 2px;
  box-shadow: 0 0 2px 2px @b-light-blue;
  border: solid 1px var(--b-blue);
  outline: none;
}

.required-right {
  &:after {
    content: "*";
  }
}

.required-left {
  &:before {
    content: "*";
  }
}

.windowed {
  border-radius: @soft-corners;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.soft-corners {
  border-radius: @soft-corners;
}

.mod--rounded-skin,
.mod--rounded-skin__class {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: @rounded-corners;
}

.mod--rounded-img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: @rounded-corners;
  overflow: hidden;
}

.paginated {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: white;
  .z-overmodal;

  &__content-size {
    max-width: @content-sizing;
    margin: auto;
  }
}

.activePaginated {
  overflow-y: hidden;
  height: 0px;
}

.removeScrolling {
  position: relative;
  overflow-y: hidden;
  height: ~"calc(100% + 69px)";
}

.clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.style-anchor {
  color: var(--b-blue);
  .common-transition;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.unstyle-anchor {
  text-decoration: none;
  color: inherit;
}

.delete-input-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-ellipsis {
  overflow: inherit;
  text-overflow: inherit;
  white-space: normal;
}

.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.inline-flex {
  display: inline-flex !important;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hidden-children {
  :nth-child(n) {
    display: none !important;
  }
}

.negative-svg-icon {
  svg {
    g,
    circle {
      stroke: white;

      path {
        stroke: white;
        fill: transparent;
      }
    }

    circle {
      fill: white;
    }
  }
}

.space-right {
  margin-right: 0.25em;
}

@keyframes spinner-loading {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

// Z-AXIS POSITION HIERARCHY
.z-background {
  z-index: -1;
}

.z-layout {
  z-index: 0;
}

.z-layer {
  z-index: 1;
}

.z-overlayer {
  z-index: 2;
}

.z-mask {
  z-index: 3;
}

.z-advs {
  z-index: 4;
}

.z-navs {
  z-index: 5;
}

.z-sticky {
  z-index: 6;
}

.z-oversticky {
  z-index: 7;
}

.z-modal {
  z-index: 8;
}

.z-overmodal {
  z-index: 9;
}

// CURSORS CUSTOM
.left-cursor {
  animation: slideLeftCursor 0.4s infinite alternate;
}

.right-cursor {
  animation: slideRightCursor 0.4s infinite alternate;
}

.left-cursor-stop {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAXpJREFUeNq0VMt1gzAQ1CMNgCugBDqwSqAEuQMfc1QHnHOSO6CEuAOVIHegdKDs6OPIGITym/fWwrCMZoddMfZPeKnIaSle4/XtLzZVfd87Wu00TW4YBlzjBzcVhaFwMTSFdEFEGSAC0iqldG+B0HqyccTNEMj52mAoEnPOPWFaZxDgZZBo7Z6AvECOStqm1pfxeqV0qnSeUc7jw9OJMTznHP96inOdYmOCGqheQojwDGvwzquuU3yLzXA8Piu9XBgTgtxXsbTRq25+3DNrpBmaRb/yKuUl0pDzkd+ygnw6HA4GmySPRfKubYN/S09zpG9B2+XE7wSHoGudiO+gIbkPxBqptXk/9zlxn4ZBKeUwbZsdgDzK8f1MQvymqCiQijUXJ5AW8UiSBwZj8xu1XddZi7L2QEplIBt3xzjiLNcGYQGtcSww9d0uNQZfuABYVhzd7YnmRbWwjNUck2vnMXo49xtVwKZIOvzmsJfo6XjIa4o5lr+r9FOAAQDwBzF/D//WkAAAAABJRU5ErkJggg=="),
    auto;
  animation: inherit;
}

.right-cursor-stop {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAcJJREFUeNq0VdFtwjAQPdH+Y/ivFPHVT0sMgLNBNiD89a8wAWwQNohQB0iYIGyQbpBsYDZw78UODYGEFKlPOiV2zu+Od3eG6J/w8sQZwfbB9s72PfiUIZJsMZtmM85yttC57BgmCAIjhDCz2UzzXtDmeW2R4nBcLQL2lZLofCZKU0lliWCfb0SnxWJRuSyXS2Jy4fs+O1J6l9jYqJY0SSxxjSgi2u+JNhv5xeEumgjR+ctHjfeIPA/eRKsVq9eSb72GCKT4VZXlQ0lHLlv4e7TdEmWZ/eL7t+T4juDH4zBissS/uvaRK3Y9nQYT16LZZx85fFDQgcRWtLRR2C5yZCtlF59Hrrg1sWU8HK7d2uToDARgycbjcbsrQilloZTK0VfNHo6qYeDmv0GeG54GOyx4an35lGUZb1PGpLytTRiG5mpgeCXchBmjlDFJYkxR4KQNBkIY1g3UZAX71kG6RnrXGmdzCYbMe4CsqTFA98iRvWJb79i5yvwB4hgTz0M2FAqZPgDkmEwm2t18wzCdTgvdKNY94LbD0P/1/l2jOF3Ic9Sbimf/ECJIkqBTWqTz+Vz3FmwAPFecDJe70zTpIv0RYAARJ6v70ynSCwAAAABJRU5ErkJggg=="),
    auto;
  animation: inherit;
}

@keyframes slideLeftCursor {
  0% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASVJREFUeNrUlMGNgzAQRSNtA4EKEBW4hJRACS7BJbgDSnAJlOASfN0bJfi6t1k/NF5FkRKw4LJfGgkh+P/7z4xvt/+Grws4bKlHqe9SP1cZexSI917K81LKXEUcYoyyLIsYY2SaJgTcadZxHLMUWGulCpTX/nS2zjnJOW9uq0BrHEad0KhYKlHrukoIQapA3/drq7vEMYdh2BrFsatLcq0CrTEM/AyYAJzN87wVhM8CfNtCPOMGQFRJXgU0nuMgN3mDmi3kmv/xBaDTeyAOjcFpzruRBJr2CSklSHPXdZnI1P3udEQ5AAiJBBF1by8hBowgro8uyN9E7DnWqTg8bndccMRPk6E327116wwjR1OeQaa6FOHMhYMbz22GQ0rzfNukXwEGAKyMyaVM6GQ/AAAAAElFTkSuQmCC"),
      auto;
  }

  14.28571428571429% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASVJREFUeNrUlMGNgzAQRSNtA4EKEBW4hJRACS7BJbgDSnAJlOASfN0bJfi6t1k/NF5FkRKw4LJfGgkh+P/7z4xvt/+Grws4bKlHqe9SP1cZexSI917K81LKXEUcYoyyLIsYY2SaJgTcadZxHLMUWGulCpTX/nS2zjnJOW9uq0BrHEad0KhYKlHrukoIQapA3/drq7vEMYdh2BrFsatLcq0CrTEM/AyYAJzN87wVhM8CfNtCPOMGQFRJXgU0nuMgN3mDmi3kmv/xBaDTeyAOjcFpzruRBJr2CSklSHPXdZnI1P3udEQ5AAiJBBF1by8hBowgro8uyN9E7DnWqTg8bndccMRPk6E327116wwjR1OeQaa6FOHMhYMbz22GQ0rzfNukXwEGAKyMyaVM6GQ/AAAAAElFTkSuQmCC"),
      auto;
  }

  28.57142857142857% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARhJREFUeNrMVO0JgzAQDaUDRPG/wd9CM4IjOEJGyAh2AnECR3CE4ATFCXSDuEF61xrQUklsU+iDIwHju3v3RcgfoQCTYDQkKeOcGymlgbtmjOHZBom2qiqDQAf2fPfw9KkHSunmDEbsws+Iz77KlyKxUMR0aa1LXdcldAEZhiEIMfDVou97AtX3akewyYeYQc8+Km+rn6bp8wPbZIUmSaLyPOegaNZaR64IlHGgKArs41vXdUYphXflI81JjAPTtu3aCQ9CbIHkR8bbixhyaqIo0uvF5BqQeZomp/emaQiQX/G9b8TS5m8P4zhiCsbDaxIlotQ9lGVplh19GBzJMfJXB0KIr/exHW2VZZnGtorjGOVXez/cBRgAciBB+5F18y0AAAAASUVORK5CYII="),
      auto;
  }

  42.85714285714286% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAVdJREFUeNrcU8FthDAQdHINGF7ws6iAEqgA8eTpEiiB3ymvUyqwqACogBKgA7hfftCBs+PDUpTcBRPulZFWRgaPh5ldxv4bYipFdfE8b/Z9f6Rn8Qzirq5rnSSJ7rpOK6U0LnkGcd33vSEsikIDq+rDyCxhHMdmlVJCdXKYOYqi2RLCFliy+n4YyhKCHMBlW4deHbqCtW3LKEA2DANbloWlacppW1Jh7WAT8nCWihaDWhyc59kEiCDHcbREsixLa0/p3L+cc0OIw5YQzzZM+qa3ewj6K8HpAfGZiGQYhubXs+x2hsiMJUAQBIzeB3mes6qqYNMbbX9sKS6gEGqhaAsYIFd7BanUroAle9qsd1GLybzX17+123vTNJu3T9OE5bpHMRdCbCpGC/5lxBV69BEQ7jqFfC+xwJDAx++A/wh4ncAfeHEclktyg/EUo02FACqqu0F8CjAAZ3SOQ3DPz1AAAAAASUVORK5CYII="),
      auto;
  }

  57.14285714285714% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASxJREFUeNrcVNENgyAUfGk6ABoHYAMdQTdwBEZxA0dgBEfADXADuoFuYDkCpmmIhdj+9BLCS4R7x3FI9I9ofkGq27bd7Sy/Rtg0zY4BCCFAPl4/+wvhOI6u9srFVe5JSukI0QT1uq6ufvMcjdachgx2KKUOQq21G0VRgIhjEed8n6Yp+w4YSAIhSNAEzdDUjh5WAbZWuZb0UAtCKAs1rIFd8D3Y9b7x/kGxtJup6zqyqmnbNprnmaxS1D1jjLwltCwL7HmkqOUhHcMwHOmIAd9xulQbpFW0Bx/P4C9weN18O8mysH5SXdcpucdUpypWIcspyEkGq6pKI2rfJnanRJYRsTOgOeKXm+U25DcGY0zsqSdDxMgRQf/EL/2zkVOD14ZRlqXxEWOxxU8BBgAl3ZL1w/+xKwAAAABJRU5ErkJggg=="),
      auto;
  }

  71.42857142857143% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATxJREFUeNrslMtRhTAUhlE3LoUKGCqghJRACekAS0gHKYESsIOUAEt32AEu3cXzOckd7vVGwh3d+c+cCSTn8Z9HUhT/+Gs8JPa1iBJ5FflI6LQijyLvucHaruv8MAy+LMtV/p+v6Ki6rr2sLtdpJzIppfy6rl9ijMHBEjKIMM45r7X2wWYXDmewhZG11oNlWTzB5HwUqcmCM/arqiLo055jSxm2bAkAO8AaSmBZQcjI5LDWsMAgsiUYjPkGsGUvommaNWSyC1IzGIzjeGK7dbYFOqI/HBnHsW3bM7YphB6ovLkTp5FtLE0K0zTF5mbBwQSjHEAkGt7/5FU6r/q+L+Z5/t37TvNy2VKuIw3kPXDcMOY6Bc6OjFxyti+dhtrqWytzmm0uSJyU8FB9ey/ubgzQhbTfRF6uPZ2fAgwAPeik0Z0cju0AAAAASUVORK5CYII="),
      auto;
  }

  85.71428571428571% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAVdJREFUeNrslNFtgzAQhp1OYBgAKBOwQRnBmQDYoCPwwjPZADagGxgmgA1gA9jAud91KisCQquo6kN/6QSWue/M77MZ+9dfk6BQFOmzwVXTNCqOY8DL7yRyipgi2Jh/JykoTVPAG5OzL9d1xzzPlRBCRVGERIlVAmgKAiKrqtJwPGnc7yzkU4DN86xsSSk1AAVhAedcF7bnHceZkW6zTrZ/lJAuy8IQBGAEYr7vo6COLQ3DwLIsw/NMw497sKTVxgBCgCOh6zo2TZOOJEkYebsKx/dhGC7EeMXQnkNGDzuwMfh1/OY4juqokGP2Yttu07MyCAK9kfD3GeAve/q+P7xi04KHwIJ8m9EFOBiPZLrjsEZAH3ldliVWm9+SXg6Ap1tXYOfX1LYtK4pioNfLWh/vHXNhTtcbWRN5nsfR46Yqq+u6pdez3WanH15G3DppgA2/dr9eBRgACml0sq5YKAQAAAAASUVORK5CYII="),
      auto;
  }

  100% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAVdJREFUeNrslNFtgzAQhp1OYBgAKBOwQRnBmQDYoCPwwjPZADagGxgmgA1gA9jAud91KisCQquo6kN/6QSWue/M77MZ+9dfk6BQFOmzwVXTNCqOY8DL7yRyipgi2Jh/JykoTVPAG5OzL9d1xzzPlRBCRVGERIlVAmgKAiKrqtJwPGnc7yzkU4DN86xsSSk1AAVhAedcF7bnHceZkW6zTrZ/lJAuy8IQBGAEYr7vo6COLQ3DwLIsw/NMw497sKTVxgBCgCOh6zo2TZOOJEkYebsKx/dhGC7EeMXQnkNGDzuwMfh1/OY4juqokGP2Yttu07MyCAK9kfD3GeAve/q+P7xi04KHwIJ8m9EFOBiPZLrjsEZAH3ldliVWm9+SXg6Ap1tXYOfX1LYtK4pioNfLWh/vHXNhTtcbWRN5nsfR46Yqq+u6pdez3WanH15G3DppgA2/dr9eBRgACml0sq5YKAQAAAAASUVORK5CYII="),
      auto;
  }
}

@keyframes slideRightCursor {
  0% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATFJREFUeNrUlN1thDAQhInSwEEFhqc8ugRKoASXQAnXAUoFpgOnA0ogHZAOnA6c/ZCR0OmEsY6XjLTC+Gd2PR67KP4b3g/GeokPie+rkmmJoe/70HVdkLa5gtS0bRu01sFaG+Z5hthdQXzfCKkWkET6b68Sqz2h9z4MwxCi3ll4e9I3C6Eex3H9kURFXdc/0vyN458UIPGVe7A9VVItVQN24ZxbdVdKre2qqpbcXdz2hMuyrIS4ZJ/MGBOig7LgHgk37ekHVC3zbLbtIATb9xmapvGnJMBuMRSLQgIk5XxTxBOHxvZjJR4/H+HsBeogZDKakoBvCmfdocuy9BzMWcia07ZD6xlZUpimKdsZkFu8eqQvu4u3MP9R4rV71Br9I6l+5WHqIMFaRHSMTb14fwIMAAaswy7TQCkBAAAAAElFTkSuQmCC"),
      auto;
  }

  14.28571428571429% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATFJREFUeNrUlN1thDAQhInSwEEFhqc8ugRKoASXQAnXAUoFpgOnA0ogHZAOnA6c/ZCR0OmEsY6XjLTC+Gd2PR67KP4b3g/GeokPie+rkmmJoe/70HVdkLa5gtS0bRu01sFaG+Z5hthdQXzfCKkWkET6b68Sqz2h9z4MwxCi3ll4e9I3C6Eex3H9kURFXdc/0vyN458UIPGVe7A9VVItVQN24ZxbdVdKre2qqpbcXdz2hMuyrIS4ZJ/MGBOig7LgHgk37ekHVC3zbLbtIATb9xmapvGnJMBuMRSLQgIk5XxTxBOHxvZjJR4/H+HsBeogZDKakoBvCmfdocuy9BzMWcia07ZD6xlZUpimKdsZkFu8eqQvu4u3MP9R4rV71Br9I6l+5WHqIMFaRHSMTb14fwIMAAaswy7TQCkBAAAAAElFTkSuQmCC"),
      auto;
  }

  28.57142857142857% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARZJREFUeNrMVMERgjAQZNSHz0ADMFSQEtIBlBB+PulAOqAE7CAlZKwgJWgHKSHeRpzxAQkizrgzO7lH2Lvc7ZEkP8J+4T1GPBGPxPsWiTvOuaPTtm3rxph/rVoUhQOEEP7sug7CYsm3u4jw6qJ2vxreXwn3RDU6ZRaHj1X7njPGeNM0EL4SL1MWnKo4aCeynB9qXddiGIbz+IJoKyxZy2RZdntPQBX6M89zH4NVVSVSyiTWkheU1toZY+BX8/JvCHRPL9yJ51LQM/1CbCXs13iJ4Bphhh5bazcXBmguMiqK5J8KAxpDDEEp5ebsFoIIuQLVlmVpMfA1K9+jJe/9RgzHpGkKUfnVz554Q/Ww4tjTNlbpQ4ABAIopWwqDS0I8AAAAAElFTkSuQmCC"),
      auto;
  }

  42.85714285714286% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATtJREFUeNrcVNFthDAMpZ0AmAAxARILZISMwAZNN2CDqBMgJoANwgawQcQEGcH1Q6FCJ+4uOe6rlqwgS3nvxX4mSf57pHme27IsHX9rzo6zegew6rqOjDFUVdV2cs2+A3h2zpHWmkBgrQWwuQpaSSkJURQF7QRcb64C62EYaJ5nOhKg75dQMTSAKaVoJ+DyEIvTYji+f1Akm6b5U7kTeGARBQxVvocKtoJC1I4EcIW3XnCItm23AcEJuAwwEN0SpGm6Kw/ycyqE2C6CANY6BpTCaiDAIKP6vat8FHgREgR+E4PCUGCgLTGrrfHkENVna/35AHhdluUp+ziOOH5igJd1XZ8C932/4UdtSZZlDk+9F94NL/2EGmwgrHUGWte1uze0j5Bl4fxiAsmZ8MYl0zQhJ65/o2Vnl34FGADYD4q/BSz61wAAAABJRU5ErkJggg=="),
      auto;
  }

  57.14285714285714% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAS9JREFUeNrklOGNhCAUhM1VgMb/Ehs4S6AESrAESqADS7AES7AESrAESuD4LmLM5jxwd+/XTUJiMMzMm/egqv4bhr8gnYdhCE3TbPFbvIvUjOMYgFIqIBD33KukGiLvfZjnOWitD4HSaHRcPq7xnGld1x7SdV3DWSD+W0ud2WVZkhNJjuTpnAssKWXYtu37u21bdyfnFTfWWogVGSLEHk4hhJgKbk0H7shuJ3bTNB1ZUnoSeIgqCwEBIEvIAS6TAE0zxiTHspRYcegKkKdxQ+BOFIZyc6ASIcSPE/FxQfwZu55V77quillXMTa1Nzg/EaEQ9ODOrSsippl784pneMbJb2Dc+r73d184iRMcXeGZGT7eBS4Jncfd2ek+YuaVl4zsLAJcGNb+/urcwS8BBgDwfaFe//l4sgAAAABJRU5ErkJggg=="),
      auto;
  }

  71.42857142857143% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAS5JREFUeNrsVO2NhCAQ9a6C0wqIFViCJVDCdiAl2AElUALXgXZACW4HbAfcPDMkZBfd8d/+uJdMJHwM770ZaZp/fAJ+KIY364bidjWxV0ol+urK2q1t22itTVrroz1VjIS0LAsO+XKeIhhjUoxxD068SZlvhOScSyxXUThcFkJIANagCKxxASc/hZnneT88DAMOuL7vo/d+n4MKzJesMX5S9loQeIfNAA7kS6AAssEaYwBssV9ig4XEGrLnmTUXdubuOIWCxDNk1mxRkHaCy4yOAGvyHigQZUWBkgBgzUmXWp7v5wkqmIjAuq7NNE0NeTxK/N2tOCpcjTX7rCSJVdlqNRQ9G/gvFEODSe7TEld69vCd6Lpuyx2AhNyzVuLpl4Q9P5sPil+Ku4TVnwADALbIpHm+d8AXAAAAAElFTkSuQmCC"),
      auto;
  }

  85.71428571428571% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAVhJREFUeNrsU7FthEAQvJedkB1UgIgIKeFKgNAZJVACHSBXAFRgVwB0cNAAIBqADGfnnRdI2M9hXnpZDjzS6tCJm52d3WXsH38JoWmaI85Hkkae5ykppaLv8lGkaRiGCkiSBMSx5j+bQiznITiFBBmQpilI5YYgokhQgW3byvd9FcexWuzSwrMsqwPZCjzEIyGEiqLomqgsS7XFOI4KloHgskdKBGVRFJx+0mau65r1fc+apmFVVbFpmhjnnJF6lmVZdfMAStEkHaAUquA7Sofqruu+qN6zQ6DMswAJiJEAiWDT0ofw+aZjnP84JkEQXG1A+XTWdPUKd5bYHxk06Sxgwem5hsco8QgoHwIcx9Fu4tP3i3meTcMwyC5xKMB1XZbn+Qfh5Z5tk9sZ3lOMxUF1OoLLwda9UblinWXM6jAMU9u2a5MaineK6R7i7f6vu1/rSH4VnwIMAO0Ld6RgY5mMAAAAAElFTkSuQmCC"),
      auto;
  }

  100% {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAVhJREFUeNrsU7FthEAQvJedkB1UgIgIKeFKgNAZJVACHSBXAFRgVwB0cNAAIBqADGfnnRdI2M9hXnpZDjzS6tCJm52d3WXsH38JoWmaI85Hkkae5ykppaLv8lGkaRiGCkiSBMSx5j+bQiznITiFBBmQpilI5YYgokhQgW3byvd9FcexWuzSwrMsqwPZCjzEIyGEiqLomqgsS7XFOI4KloHgskdKBGVRFJx+0mau65r1fc+apmFVVbFpmhjnnJF6lmVZdfMAStEkHaAUquA7Sofqruu+qN6zQ6DMswAJiJEAiWDT0ofw+aZjnP84JkEQXG1A+XTWdPUKd5bYHxk06Sxgwem5hsco8QgoHwIcx9Fu4tP3i3meTcMwyC5xKMB1XZbn+Qfh5Z5tk9sZ3lOMxUF1OoLLwda9UblinWXM6jAMU9u2a5MaineK6R7i7f6vu1/rSH4VnwIMAO0Ld6RgY5mMAAAAAElFTkSuQmCC"),
      auto;
  }
}

.img-left {
  object-position: left;
}

.img-right {
  object-position: right;
}

.img-top {
  object-position: top;
}

.img-bottom {
  object-position: bottom;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-none {
  order: initial;
}
/* UTILS - DESKTOP  */
@media (min-width: @desktop) {
  .break-container {
    margin-left: ~"calc((-100vw + " @content-sizing ~" + 17px) / 2)" !important;
    /* TODO - NECESARIO PARA ROMPER EL CONTAINER */
    margin-right: ~"calc((-100vw + " @content-sizing ~" + 17px) / 2)" !important;
    /* TODO - NECESARIO PARA ROMPER EL CONTAINER */
    width: 100vw !important;
    /* TODO - NECESARIO PARA ROMPER EL CONTAINER */
  }

  .default-container {
    margin-left: auto !important;
    /* TODO - NECESARIO PARA VOLVER EL CONTAINER A SU ESTADO ORIGINAL */
    margin-right: auto !important;
    /* TODO - NECESARIO PARA VOLVER EL CONTAINER A SU ESTADO ORIGINAL */
  }

  .break-container-mobile {
    width: 100% !important;
    /* NECESARIO PARA VOLVER EL CONTAINER A SU ESTADO ORIGINAL */
    margin-left: inherit;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:block {
    display: block !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:inline-block {
    display: inline-block !important;
  }

  .md\:inline {
    display: inline !important;
  }

  .md\:inline-flex {
    display: inline-flex !important;
  }

  .md\:img-center,
  .md\:img-center img {
    object-position: center;
  }

  .md\:img-left,
  .md\:img-left img {
    object-position: left;
  }

  .md\:img-right,
  .md\:img-right img {
    object-position: right;
  }

  .md\:img-top,
  .md\:img-top img {
    object-position: top;
  }

  .md\:img-bottom,
  .md\:img-bottom img {
    object-position: bottom;
  }
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
  .md\:order-none {
    order: initial;
  }
}

/* UTILS  - DESKTOP CONTENT - SIZE */
@media (min-width: @desktop) and (max-width: @content-sizing) {
  .paginated {
    &__content-size {
      padding: 0px 15px;
    }
  }
}

/* UTILS  - ONLY MOBILE */
@media (max-width: @desktop) {
  .sm\:img-left,
  .sm\:img-left img {
    object-position: left;
  }

  .sm\:img-right,
  .sm\:img-right img {
    object-position: right;
  }

  .sm\:img-top,
  .sm\:img-top img {
    object-position: top;
  }

  .sm\:img-bottom,
  .sm\:img-bottom img {
    object-position: bottom;
  }
}

.mod--hide-mbl {
  display: none;
}
@media (min-width: @desktop) {
  .mod--hide-dsktp {
    display: none;
  }
  .mod--hide-mbl {
    display: block;
  }
}
